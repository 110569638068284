import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { Component, useEffect } from 'react'
import RGL, { Layout, ReactGridLayoutProps, WidthProvider } from 'react-grid-layout'
import { useSelector } from 'react-redux'
import { DatePickerProps, DateTimePickerProps, TimePickerProps } from '@mui/lab'
import { Box, CheckboxProps, Stack, TextFieldProps, TypographyOwnProps } from '@mui/material'
import { MISDateTimePickerProps } from 'common/components/form/MISDateTimePicker'
import { MISTimePickerProps } from 'common/components/form/MISTimePicker'
import { selectChartingPreviewState } from 'store/selectors/charting'
import FBCheckbox from './form-builder-components/FBCheckbox'
import FBDatePicker from './form-builder-components/FBDatePicker'
import FBDateTimePicker from './form-builder-components/FBDateTimePicker'
import FBDropdown from './form-builder-components/FBDropdown'
import FBNumberFormat from './form-builder-components/FBNumberFormat'
import FBTextField from './form-builder-components/FBTextField'
import FBTimePicker from './form-builder-components/FBTimePicker'
import FBTypography from './form-builder-components/FBTypography'
import FBValueUnit from './form-builder-components/FBValueUnit'
import FormBuilderConfig from './FormBuilderConfig'
import FormBuilderToolbar, { FORM_BUILDER_COMPONENTS } from './FormBuilderToolbar'
import { ITemplate } from '../../blots/TemplateBlot'

const ReactGridLayout = WidthProvider(RGL)

export enum EFormBuilderUnitType {
  MILEAGE = 'Mileage',
  LENGTH = 'Length',
  TEMPERATURE = 'Temperature',
  TIME = 'Time',
  WEIGHT = 'Weight',
}
export type TFormBuilderComponentCustomProps = {
  allowLeadingZeros?: boolean
  allowNegative?: boolean
  decimalScale?: number
  format?: string
  prefix?: string
  suffix?: string
  thousandSeparator?: boolean
  terminologySet?: string
  unitType?: EFormBuilderUnitType
  unitValue?: string
}
export type TFormBuilderComponentData =
  | (CheckboxProps & TextFieldProps & TFormBuilderComponentCustomProps)
  | (DatePickerProps & TextFieldProps & TFormBuilderComponentCustomProps)
  | (MISDateTimePickerProps & TextFieldProps & TFormBuilderComponentCustomProps)
  | (MISTimePickerProps & TextFieldProps & TFormBuilderComponentCustomProps)
  | (TypographyOwnProps & TextFieldProps & TFormBuilderComponentCustomProps)
  | (TextFieldProps & TFormBuilderComponentCustomProps)
export type TFormBuilderComponent = {
  name: string
  data: TFormBuilderComponentData
}
export type FormBuilderTemplateState = {
  components: TFormBuilderComponent[]
  gridData?: ReactGridLayoutProps & { border?: boolean; height?: number }
  layout: Layout[]
  preview?: boolean
}

type FormBuilderTemplateProps = { data?: FormBuilderTemplateState }

type FormBuilderProps = {
  onDataChange: (data: TFormBuilderComponentData, index: number) => void
  onDataDelete: (index: number) => void
  onDragStart: (name: string) => void
  onDrop: (layout: RGL.Layout[], item: RGL.Layout) => void
  onGridDataChange: (key: string, value: boolean | number | string) => void
  onLayoutChange: (layout: Layout[], preview: boolean) => void
  onPreviewChange: (preview: boolean) => void
} & FormBuilderTemplateState

const FormBuilder = ({
  components,
  gridData,
  layout,
  onDataChange,
  onDataDelete,
  onDragStart,
  onDrop,
  onGridDataChange,
  onLayoutChange,
  onPreviewChange,
}: FormBuilderProps) => {
  const preview = useSelector(selectChartingPreviewState)

  useEffect(() => {
    onPreviewChange(preview)
  }, [onPreviewChange, preview])

  return (
    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
      <ReactGridLayout
        cols={gridData?.cols || 4}
        compactType={null}
        containerPadding={[0, 0]}
        isDraggable={!preview}
        isDroppable={!preview}
        isResizable={!preview}
        layout={layout}
        margin={gridData?.border ? [-1, -1] : [0, 0]}
        onDrop={onDrop}
        onLayoutChange={(layout) => onLayoutChange(layout, preview)}
        rowHeight={gridData?.height || 48}
        style={{
          border: preview ? 'none' : '1px dotted #ccc',
          marginBottom: '8px',
          minHeight: '96px',
          width: '100%',
        }}
      >
        {components.map((component, index) => (
          <Box
            component="div"
            key={index.toString()}
            sx={{
              border: gridData?.border ? '1px solid #333' : preview ? 'none' : '1px dotted #ccc',
            }}
          >
            <Box sx={{ m: 0.25 }}>
              {component.name === FORM_BUILDER_COMPONENTS.FBCheckbox.name && (
                <FBCheckbox
                  data={
                    component.data as CheckboxProps & TextFieldProps & { terminologySet?: string }
                  }
                  onChange={(checked: boolean) => {
                    onDataChange(
                      { ...component.data, checked } as CheckboxProps &
                        TextFieldProps & { terminologySet?: string },
                      index
                    )
                  }}
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBDatePicker.name && (
                <FBDatePicker
                  data={component.data as DatePickerProps & TextFieldProps}
                  onChange={(value) => onDataChange({ ...component.data, value }, index)}
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBDateTimePicker.name && (
                <FBDateTimePicker
                  data={component.data as DateTimePickerProps & TextFieldProps}
                  onChange={(value) => onDataChange({ ...component.data, value }, index)}
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBDropdown.name && (
                <FBDropdown
                  data={
                    component.data as CheckboxProps & TextFieldProps & { terminologySet?: string }
                  }
                  onChange={(e) =>
                    onDataChange({ ...component.data, value: e.target.value }, index)
                  }
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  onTerminologySelect={(e) =>
                    onDataChange({ ...component.data, terminologySet: e.target.value }, index)
                  }
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBNumberFormat.name && (
                <FBNumberFormat
                  data={component.data}
                  onChange={(e) =>
                    onDataChange({ ...component.data, value: e.target.value }, index)
                  }
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBTextField.name && (
                <FBTextField
                  data={component.data}
                  onChange={(e) =>
                    onDataChange({ ...component.data, value: e.target.value }, index)
                  }
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBTimePicker.name && (
                <FBTimePicker
                  data={component.data as TimePickerProps & TextFieldProps}
                  onChange={(value) => onDataChange({ ...component.data, value }, index)}
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBTypography.name && (
                <FBTypography
                  data={component.data as TypographyOwnProps & TextFieldProps}
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
              {component.name === FORM_BUILDER_COMPONENTS.FBValueUnit.name && (
                <FBValueUnit
                  data={component.data as TypographyOwnProps & TextFieldProps}
                  onChangeUnitValue={(e) =>
                    onDataChange({ ...component.data, unitValue: e.target.value }, index)
                  }
                  onChangeValue={(e) =>
                    onDataChange({ ...component.data, value: e.target.value }, index)
                  }
                  onDelete={() => onDataDelete(index)}
                  onSave={(data: TFormBuilderComponentData) => onDataChange(data, index)}
                  preview={!!preview}
                />
              )}
            </Box>
          </Box>
        ))}
      </ReactGridLayout>
      <Box sx={{ display: preview ? 'none' : 'block' }}>
        <FormBuilderConfig data={gridData} onChange={onGridDataChange} />
        <FormBuilderToolbar onDragStart={onDragStart} />
      </Box>
    </Stack>
  )
}

class FormBuilderTemplate
  extends Component<FormBuilderTemplateProps, FormBuilderTemplateState>
  implements ITemplate
{
  type = 'FormBuilderTemplate'
  defaultLayout: Layout[] = [{ h: 3, i: '-1', w: 1, x: 5, y: 5 }]
  draggedElement = ''
  state: FormBuilderTemplateState

  constructor(props: FormBuilderTemplateProps) {
    super(props)
    this.state = {
      ...props.data,
      components: props.data?.components || [],
      layout: props.data?.layout || this.defaultLayout,
    }
  }

  getData = () => ({
    components: this.state.components,
    gridData: this.state.gridData,
    layout: this.state.layout,
  })

  handleDataChange = (data: TFormBuilderComponentData, index: number) => {
    const newData = { ...this.state.components[index], data }
    const components = [...this.state.components]
    components[index] = newData
    this.setState({ ...this.state, components })
  }

  handleDataDelete = (index: number) => {
    const components = [...this.state.components]
    const layout = [...this.state.layout]
    components.splice(index, 1)
    if (layout.length !== 0) layout.splice(index, 1)
    for (let i = index; i < layout.length; i++) {
      layout[i].i = (Number.parseInt(layout[i].i) - 1).toString()
    }
    this.setState({
      ...this.state,
      components,
      layout: layout.length === 0 ? this.defaultLayout : layout,
    })
  }

  handleDragStart = (name: string) => {
    this.draggedElement = name
  }

  handleDrop = (layout: RGL.Layout[], item: RGL.Layout) => {
    const name = FORM_BUILDER_COMPONENTS[this.draggedElement]?.name
    const components = [...this.state.components]
    components.push({ data: {}, name })
    layout.splice(layout.length - 1, 1, { ...item, i: `${layout.length - 1}` })
    this.setState({ ...this.state, components, layout })
    this.draggedElement = ''
  }

  handleGridDataChange = (key: string, value: boolean | number | string) => {
    this.setState({
      ...this.state,
      gridData: { ...this.state.gridData, [key]: value },
    })
  }

  handleLayoutChange = (layout: Layout[], preview: boolean) => {
    this.setState({
      ...this.state,
      layout: layout.map((layout) => ({ ...layout, isDraggable: !preview })),
    })
  }

  handlePreviewChange = (preview: boolean) => {
    this.setState({
      layout: this.state.layout.map((layout) => ({ ...layout, isDraggable: !preview })),
    })
  }

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return (
      <FormBuilder
        {...this.state}
        onDataChange={this.handleDataChange}
        onDataDelete={this.handleDataDelete}
        onDragStart={this.handleDragStart}
        onDrop={this.handleDrop}
        onGridDataChange={this.handleGridDataChange}
        onLayoutChange={this.handleLayoutChange}
        onPreviewChange={this.handlePreviewChange}
      />
    )
  }
}

export default FormBuilderTemplate
