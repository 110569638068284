import { useCallback } from 'react'
import CloseIcon from '@mui/icons-material/Cancel'
import { Card, CardContent, IconButton, Typography } from '@mui/material'
import { DocumentDetails } from 'modules/charting/Charting'

type FileItemProps = {
  documentDetails: DocumentDetails
  onDelete: () => void
  onDownload: () => void
}

const FileItem = ({ documentDetails, onDelete, onDownload }: FileItemProps) => {
  const calculateSize = useCallback((size: number) => {
    if (!size) return '0 bytes'

    if (size / 1024 < 1) return `${size} bytes`
    else if (size / (1024 * 1024) < 1) return `${(size / 1024).toFixed(2)} KB`
    else if (size / (1024 * 1024 * 1024) < 1) return `${(size / (1024 * 1024)).toFixed(2)} MB`
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
  }, [])
  return (
    <Card
      onClick={documentDetails.fileId ? onDownload : undefined}
      sx={{
        alignItems: 'center',
        border: '1px solid #cbcbcb',
        borderRadius: 1,
        cursor: documentDetails.fileId ? 'pointer' : 'default',
        display: 'flex',
        height: '40px',
        margin: 0.5,
        maxWidth: '200px',
        minWidth: '100px',
        padding: 1,
      }}
    >
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          onDelete()
        }}
        size="small"
        sx={{
          '&:hover': {
            backgroundColor: '#f0f0f0',
            color: '#b64f04',
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <CardContent
        sx={{
          flexGrow: 1,
          lineHeight: 0,
          overflow: 'hidden',
          padding: 1,
          paddingBottom: '8px !important',
        }}
      >
        <Typography noWrap variant="subtitle2">
          {documentDetails.name}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          {calculateSize(documentDetails.fileSize)}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default FileItem
