import { SyntheticEvent } from 'react'
import { Autocomplete, FormControl } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import MISChip from 'common/components/form/MISChip'
import MISTextField from 'common/components/form/MISTextField'
import misTheme from 'core/styles/theme'
import { PersonnelDTO } from 'services/openapi'

export type MutliValueOptions = { provider: PersonnelDTO; label: string }

type MultiValueAutocompleteCustomProps = {
  label: string | React.ReactNode
  required?: boolean
  maxLength?: number
  error?: boolean
  helperText?: string
  inputValue?: string
  defaultValue?: string[]
  options: any
  value: any
  onBlur?: (event: SyntheticEvent) => void
  onChange: (event: SyntheticEvent, newValue: string[]) => void
  onInputChange?: (newValue: string) => void
  allowFreeText?: boolean
  placeholder?: string
  renderOption?: (props: any, option: any) => any
  shrinkLabel?: boolean
}

const CustomMultiValueAutocomplete = ({
  allowFreeText = true,
  defaultValue = [],
  error,
  helperText,
  inputValue,
  label,
  onBlur,
  onChange,
  onInputChange,
  options,
  placeholder,
  renderOption,
  required = false,
  shrinkLabel = false,
  value = [],
}: MultiValueAutocompleteCustomProps) => {
  return (
    <ThemeProvider theme={misTheme}>
      <FormControl>
        <Autocomplete
          defaultValue={defaultValue}
          freeSolo={allowFreeText}
          id="tags-filled"
          inputValue={inputValue}
          multiple
          noOptionsText="test"
          onBlur={(event) => onBlur && onBlur(event)}
          onChange={(event, value) => onChange(event, value)}
          onInputChange={(event, value) => onInputChange && onInputChange(value)}
          options={options}
          renderInput={(params) => (
            <MISTextField
              {...params}
              InputLabelProps={shrinkLabel ? { shrink: shrinkLabel } : {}}
              error={error}
              helperText={helperText}
              label={label}
              placeholder={placeholder}
              required={required}
            />
          )}
          renderOption={renderOption}
          renderTags={(value: readonly MutliValueOptions[], getTagProps) =>
            value.map((option: MutliValueOptions, index: number) => (
              <MISChip
                {...getTagProps({ index })}
                key={index}
                label={option.label}
                size="small"
                variant="outlined"
              />
            ))
          }
          value={value}
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default CustomMultiValueAutocomplete
