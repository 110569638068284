import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISRadio from 'common/components/form/MISRadio'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISAccordionContainer from 'common/components/MISAccordionContainer'
import MISListContainer from 'common/components/MISListContainer'
import GLOBAL from 'common/styles/global.scss'
import { isDateBeforeToday, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import { CodedRef } from 'services/openapi'
import {
  MIS_CONTACT_EMAIL_TYPES,
  MIS_CONTACT_METHODS,
  MIS_CONTACT_PHONE_TYPES,
  MIS_CONTACT_SOCIAL_MEDIA_TYPES,
} from 'services/terminologyConstants'
import { MODALS } from './constants'
import { ErrorType, getRowTitle } from './utils'

const ENTITY = 'Contact'

export const formatPhoneNumber = (input: string | undefined): string => {
  if (!input) return ''
  const phoneNumber = input.replace(/[^\d]/g, '')
  if (phoneNumber.length < 4) return phoneNumber
  if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export type IDHEContact = {
  id?: string
  createdUser?: string
  createdDate?: string
  changedUser?: string
  changedDate?: string
  tenantId?: string
  startDate?: string
  endDate?: string
  isDeleted?: boolean
  contactMethod?: CodedRef
  contactMethodType?: CodedRef
  note?: string
  preferredFlag?: boolean
  value?: string
  textMessageOkay?: boolean
  isParent?: boolean
  isCollapsed?: boolean
  errors?: ErrorType[]
}

type ContactsProps = {
  addContact?: () => void
  addLabel?: string
  contacts: IDHEContact[]
  deleteContact?: (index: number) => void
  namesForDepartment?: (string | undefined)[]
  onSave: (contacts: IDHEContact[]) => void
  parentContacts?: IDHEContact[]
  saveTriggered?: boolean
  setContacts: (contacts: IDHEContact[]) => void
  toggleSaveTriggered?: (saveTriggered: boolean) => void
}

const Contacts = ({
  addContact,
  addLabel,
  contacts,
  deleteContact,
  namesForDepartment,
  onSave,
  parentContacts,
  saveTriggered,
  setContacts,
  toggleSaveTriggered,
}: ContactsProps) => {
  const { t } = useTranslation('common')
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const contactMethods = useRecoilValue(terminologySelector(MIS_CONTACT_METHODS))
  const contactPhoneTypes = useRecoilValue(terminologySelector(MIS_CONTACT_PHONE_TYPES))
  const contactEmailTypes = useRecoilValue(terminologySelector(MIS_CONTACT_EMAIL_TYPES))
  const contactSocialMediaTypes = useRecoilValue(
    terminologySelector(MIS_CONTACT_SOCIAL_MEDIA_TYPES)
  )
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState({
    index: -1,
    open: false,
  })
  const [preferredWarningDialog, setPreferredWarningDialog] = useState({ data: {}, open: false })
  const [hasPreferredExpiredWarning, setHasPreferredExpiredWarning] = useState(false)
  const [pageErrors, setPageErrors] = useState<any>([])

  const hasError = (contact: IDHEContact, field: string) => {
    const rowError = contact?.errors?.filter((error: ErrorType) => {
      return error.field === field
    })
    if (rowError && rowError[0]) {
      return true
    } else {
      return false
    }
  }

  const getError = (contact: IDHEContact, field: string) => {
    const rowError = contact?.errors?.filter((error: ErrorType) => {
      return error.field === field
    })
    if (rowError && rowError[0]) {
      return rowError[0].message
    } else {
      return ''
    }
  }

  const getContactValueLabel = useCallback(
    (contactMethodCode: string) => {
      switch (contactMethodCode) {
        case 'PHONE':
          return t('client-contacts.phone-number')
        case 'EMAIL':
          return t('client-contacts.email-address')
        case 'SOCIAL_MEDIA_WEBSITE':
          return t('client-contacts.social-media-account')
      }
      return t('client-contacts.value')
    },
    [t]
  )

  const getContactMethodTypes = useCallback(
    (contactMethodCode: string) => {
      switch (contactMethodCode) {
        case 'PHONE':
          return contactPhoneTypes
        case 'EMAIL':
          return contactEmailTypes
        case 'SOCIAL_MEDIA_WEBSITE':
          return contactSocialMediaTypes
      }
      return []
    },
    [contactEmailTypes, contactPhoneTypes, contactSocialMediaTypes]
  )

  const validate = useCallback(() => {
    let isValid = true
    let preferredExists = false
    let preferredExpiredExists = false

    const validatedContacts: IDHEContact[] = contacts.map((contact: IDHEContact, index: number) => {
      const errors: ErrorType[] = []
      if (contact?.preferredFlag) {
        preferredExists = true
        preferredExpiredExists = isDateBeforeToday(contact?.endDate)
      }

      if (!contact.contactMethod) {
        isValid = false
        errors.push({
          field: `contact.${index}.contact-method`,
          message: t('client-contacts.validation.contact-method-required'),
        })
      }
      if (!contact.contactMethodType) {
        isValid = false
        errors.push({
          field: `contact.${index}.contact-method-type`,
          message: t('client-contacts.validation.contact-method-type-required'),
        })
      }
      if (!contact.value) {
        isValid = false
        errors.push({
          field: `contact.${index}.contact-value`,
          message: t('client-contacts.validation.contact-value-required'),
        })
      } else {
        if (contact?.contactMethod?.code === 'EMAIL') {
          const isValidEmail = /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/.test(
            contact.value
          )
          if (!isValidEmail) {
            isValid = false
            errors.push({
              field: `contact.${index}.contact-value`,
              message: t('client-contacts.validation.incorrect-email'),
            })
          }
        }
        if (contact?.contactMethod?.code === 'PHONE') {
          const isValidPhoneNumber = /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/.test(
            contact.value
          )
          if (!isValidPhoneNumber) {
            isValid = false
            errors.push({
              field: `contact.${index}.contact-value`,
              message: t('client-contacts.validation.incorrect-phone-number'),
            })
          }
        }
      }

      if (!contact.startDate) {
        isValid = false
        errors.push({
          field: `contact.${index}.start-date`,
          message: t('client-contacts.validation.contact-start-date-required'),
        })
      }

      return {
        ...contact,
        errors: errors,
      }
    })

    if (!isValid) {
      return {
        displayPreferredExpiredWarning: preferredExpiredExists,
        errors: ['Validation errors, expand the rows to view'],
        isValid: false,
        validatedContacts,
      }
    }

    const contactsErrors = []
    if (validatedContacts.length > 0 && !preferredExists) {
      contactsErrors.push(t('client-contacts.validation.atleast-one-preffered-contact'))
    }

    return {
      displayPreferredExpiredWarning: preferredExpiredExists,
      errors: contactsErrors,
      isValid: contactsErrors.length === 0,
      validatedContacts,
    }
  }, [contacts, t])

  useEffect(() => {
    if (saveTriggered) {
      setIsDirty(false)

      const { displayPreferredExpiredWarning, errors, isValid, validatedContacts } = validate()
      if (!isValid) {
        setContacts(validatedContacts)
        setPageErrors(errors)
        toggleSaveTriggered && toggleSaveTriggered(false)
      } else if (displayPreferredExpiredWarning) {
        setHasPreferredExpiredWarning(true)
      } else {
        setPageErrors([])
        onSave(validatedContacts)
      }
    }
  }, [
    parentContacts,
    setContacts,
    saveTriggered,
    toggleSaveTriggered,
    validate,
    onSave,
    setIsDirty,
    t,
  ])

  const rowHeader = (contact: any, currentIndex: number, onChangeRow: any) => {
    const rowTitle = getRowTitle([
      contact?.contactMethod?.code === 'PHONE' ? formatPhoneNumber(contact?.value) : contact?.value,
      getContactMethodTypes(contact?.contactMethod?.code).find(
        (a) => a.code === contact?.contactMethodType?.code
      )?.name,
      contact?.textMessageOkay ? 'OK to Text' : '',
      `${isoDateToDisplayFormat(contact?.startDate)} ${
        contact?.endDate ? 'to ' + isoDateToDisplayFormat(contact?.endDate) : ''
      }`,
    ])

    return (
      <>
        {!contact?.isParent && (
          <MISRadio
            checked={contact?.preferredFlag}
            disabled={contact?.endDate && isDateBeforeToday(contact?.endDate)}
            name="contacts"
            onChange={() => {
              onChangeRow(currentIndex, 'preferredFlag', !contact?.preferredFlag)
            }}
            onClick={(event) => {
              event.stopPropagation()
            }}
            sx={{
              pointerEvents: 'auto',
            }}
          />
        )}
        {rowTitle && <span>{`${rowTitle}`}</span>}
        {contact?.preferredFlag && (
          <>
            {` | `}
            <span style={{ color: GLOBAL.BUTTON_PRIMARY_BG_COLOR }}>{`${
              isDateBeforeToday(contact?.endDate)
                ? t('client-contacts.preferred-expired')
                : t('client-contacts.preferred')
            }`}</span>
          </>
        )}
      </>
    )
  }

  const updateContact = useCallback(
    (currentIndex: number, newContact: any) => {
      const updatedContacts = contacts.map((contact: any, index: number) => {
        if (currentIndex === index) {
          return newContact
        }
        return contact
      })
      setContacts([...updatedContacts])
    },
    [contacts, setContacts]
  )

  const rowDataForm = useCallback(
    (contact: any, currentIndex: number, onChangeRow: any) => {
      return (
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          <Grid item xs={4}>
            {contactMethods && (
              <MISSelectDropdown
                error={hasError(contact, `contact.${currentIndex}.contact-method`)}
                helperText={getError(contact, `contact.${currentIndex}.contact-method`)}
                label={t('client-contacts.contact-method')}
                onChange={(event) => {
                  updateContact(currentIndex, {
                    ...contact,
                    contactMethod: event.target.value,
                    contactMethodType: '',
                    textMessageOkay: false,
                    value: '',
                  })
                }}
                options={contactMethods?.map((option) => ({
                  label: option.name as string,
                  value: option,
                }))}
                required
                value={contactMethods.find((a) => a.code === contact?.contactMethod?.code) || ''}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <MISSelectDropdown
              disabled={!contact?.contactMethod}
              error={hasError(contact, `contact.${currentIndex}.contact-method-type`)}
              helperText={getError(contact, `contact.${currentIndex}.contact-method-type`)}
              label={t('client-contacts.type')}
              onChange={(event) => {
                onChangeRow(currentIndex, 'contactMethodType', event.target.value)
              }}
              options={getContactMethodTypes(contact?.contactMethod?.code)?.map((option) => {
                return { label: option.name as string, value: option }
              })}
              required
              value={
                getContactMethodTypes(contact?.contactMethod?.code).find(
                  (a) => a.code === contact?.contactMethodType?.code
                ) || ''
              }
            />
          </Grid>
          <Grid item xs={contact?.contactMethod?.code === 'PHONE' ? 2.5 : 4}>
            <MISTextField
              // disableOnChangeUpdate={contact?.contactMethod?.code === 'PHONE'}
              disabled={!contact?.contactMethod}
              error={hasError(contact, `contact.${currentIndex}.contact-value`)}
              helperText={getError(contact, `contact.${currentIndex}.contact-value`)}
              label={getContactValueLabel(contact?.contactMethod?.code)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value =
                  contact?.contactMethod?.code === 'PHONE'
                    ? event.target.value.replace(/[^\d]/g, '').slice(0, 10)
                    : event.target.value
                onChangeRow(currentIndex, 'value', value)
              }}
              required
              value={
                contact?.contactMethod?.code === 'PHONE'
                  ? formatPhoneNumber(contact?.value)
                  : contact?.value
              }
            />
          </Grid>
          {contact?.contactMethod?.code === 'PHONE' && (
            <Grid item xs={1.5}>
              <FormControlLabel
                control={
                  <MISCheckbox
                    checked={contact?.textMessageOkay ? true : false}
                    onChange={(event) =>
                      onChangeRow(currentIndex, 'textMessageOkay', event.target.checked)
                    }
                  />
                }
                label={t('client-contacts.ok-to-text')}
                sx={{ height: '100%' }}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <MISDatePicker
              error={hasError(contact, `contact.${currentIndex}.start-date`)}
              helperText={getError(contact, `contact.${currentIndex}.start-date`)}
              isDefaultToday
              label={t('client-contacts.start-date')}
              onChange={(value) => onChangeRow(currentIndex, 'startDate', value)}
              readOnly={false}
              required
              value={contact?.startDate || ''}
            />
          </Grid>
          <Grid item xs={2}>
            <MISDatePicker
              label={t('client-contacts.end-date')}
              onChange={(value) => onChangeRow(currentIndex, 'endDate', value)}
              readOnly={false}
              required={false}
              shouldDisableDate={(date: any) => {
                return contact?.startDate ? date.toISODate() <= contact?.startDate : false
              }}
              value={contact?.endDate || ''}
            />
          </Grid>
          <Grid item xs={4}>
            <MISTextField
              inputProps={{ maxLength: 255 }}
              label={t('client-contacts.note')}
              onChange={(event) => onChangeRow(currentIndex, 'note', event.target.value)}
              value={contact?.note}
            />
          </Grid>
        </Grid>
      )
    },
    [contactMethods, getContactMethodTypes, getContactValueLabel, t, updateContact]
  )

  const onDeleteWarningClose = useCallback(() => {
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [deleteConfirmationDialog])

  const onDeleteWarningSave = useCallback(() => {
    const onRemoveContact = (currentIndex: number) => {
      if (deleteContact && contacts[currentIndex].id) {
        deleteContact(currentIndex)
      } else {
        const updatedContacts = contacts.filter((contact: any, index: number) => {
          return currentIndex !== index
        })
        setContacts([...updatedContacts])
      }
      setPageErrors([])
    }

    onRemoveContact(deleteConfirmationDialog.index)
    setDeleteConfirmationDialog({ ...deleteConfirmationDialog, open: false })
  }, [contacts, setContacts, deleteContact, deleteConfirmationDialog])

  const onPreferredWarningClose = useCallback(() => {
    toggleSaveTriggered && toggleSaveTriggered(false)
    setPreferredWarningDialog({ data: {}, open: false })
  }, [toggleSaveTriggered])

  const onPreferredWarningSave = useCallback(
    ({ currentIndex, field, value }: any) => {
      setPreferredWarningDialog({ ...preferredWarningDialog, open: false })
      const updatedContacts = contacts.map((contact: any, index: number) => {
        const newContact = { ...contact }
        if (field === 'preferredFlag') {
          Object.assign(newContact, { preferredFlag: false })
        }
        if (currentIndex === index) {
          const attr = Object.keys(contact).filter((r) => r === field)
          const fieldName: string = attr[0]
          Object.assign(newContact, { [fieldName]: value })
        }
        return newContact
      })
      setContacts([...updatedContacts])
    },
    [contacts, setContacts, preferredWarningDialog]
  )

  const onPreferredExpiredWarningSave = useCallback(() => {
    onSave(contacts)
    setHasPreferredExpiredWarning(false)
  }, [contacts, onSave])

  const onChangeRow = (currentIndex: number, field: string, value: any) => {
    if (!isDirty) setIsDirty(true)

    const updatedContacts = contacts.map((contact: any, index: number) => {
      if (field === 'preferredFlag' && currentIndex === index) {
        setPreferredWarningDialog({
          data: { currentIndex: currentIndex, field: field, value: value },
          open: true,
        })
        return { ...contact }
      }

      if (currentIndex === index) {
        const attr = Object.keys(contact).filter((r) => r === field)
        const newContact = { ...contact }
        const fieldName: string = attr[0]
        Object.assign(newContact, { [fieldName]: value })
        return newContact
      }
      return contact
    })
    setContacts([...updatedContacts])
  }

  const onCollapseRow = (currentIndex: number) => {
    const updatedContacts = contacts.map((contact: any, index: number) => {
      if (currentIndex === index) {
        return { ...contact, isCollapsed: !contact.isCollapsed }
      }
      return contact
    })
    setContacts([...updatedContacts])
  }

  return (
    <Box sx={{ pr: 3, pt: 2 }}>
      {parentContacts && parentContacts.length > 0 && namesForDepartment && (
        <>
          <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6">
            {namesForDepartment[0]}
          </Typography>
          <MISListContainer
            isDisabled
            onChangeRow={onChangeRow}
            onCollapseRow={onCollapseRow}
            rowForm={rowHeader}
            rows={parentContacts}
          />
          <Typography sx={{ fontWeight: 'bold', mb: 2, mt: 3 }} variant="h6">
            {namesForDepartment[1]}
          </Typography>
        </>
      )}
      {contacts && (
        <MISAccordionContainer
          addLabel={addLabel}
          emptyDataMessage={t('client-contacts.empty-message')}
          errors={pageErrors}
          onAddRow={addContact}
          onChangeRow={onChangeRow}
          onCollapseRow={onCollapseRow}
          onRemoveRow={(index: number) => {
            setDeleteConfirmationDialog({ index: index, open: true })
          }}
          rowForm={rowDataForm}
          rowHeader={rowHeader}
          rows={contacts}
        />
      )}
      <WarningDialog
        entity={ENTITY}
        onCancel={onDeleteWarningClose}
        onSave={onDeleteWarningSave}
        open={deleteConfirmationDialog.open}
        type={MODALS.DELETE_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onCancel={() => onPreferredWarningClose()}
        onSave={() => onPreferredWarningSave(preferredWarningDialog.data)}
        open={preferredWarningDialog.open}
        type={MODALS.PREFERRED_WARNING}
      />
      <WarningDialog
        entity={ENTITY}
        onSave={onPreferredExpiredWarningSave}
        open={hasPreferredExpiredWarning}
        type={MODALS.PREFERRED_EXPIRED_WARNING}
      />
    </Box>
  )
}

export default Contacts
