import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Box, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import MISCheckbox from 'common/components/form/MISCheckbox'
import MISRadioGroup from 'common/components/form/MISRadioGroup'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import MISTimePicker from 'common/components/form/MISTimePicker'
import global from 'common/styles/global.scss'
import {
  dateNowIsoString,
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormatWithTime,
} from 'common/utils/DateUtils'
import { getClientLegalName } from 'modules/shared/clientUtils'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import {
  CodedConceptControllerService,
  CodedConceptDto,
  PersonnelDTO,
  UnPagedCodedConceptDto,
} from 'services/openapi'
import {
  selectChartingClientIdInContext,
  selectChartingValidationActive,
} from 'store/selectors/charting'
import { selectClientId } from 'store/selectors/client'
import { selectUserId } from 'store/selectors/user'
import {
  EReferralTemplateStateValue,
  ReferralTemplateState,
  TReferralTemplateValue,
} from './ReferralTemplate'
import { HealthConcernControllerService } from '../../../../../services/openapi/services/HealthConcernControllerService'

type ReferralTemplateComponentProps = ReferralTemplateState & {
  onChange: (key: string, value: TReferralTemplateValue, lastUpdatedBy?: string) => void
}

const ReferralTemplateComponent = ({
  appointmentBooked,
  appointmentDate,
  appointmentLocation,
  appointmentTime,
  clientId,
  createdBy,
  createdOn,
  diagnosis,
  history,
  lastUpdated,
  lastUpdatedBy,
  onChange,
  phiWithheld,
  reason,
  referredBy,
  referredTo,
  requestedConsultationComment,
  requestedConsultationFirstAvailable,
  requestedTests,
  sentBy,
  state,
}: ReferralTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const clientIdInContext = useSelector(selectChartingClientIdInContext)
  const clientIdInState = useSelector(selectClientId)
  const userId = useSelector(selectUserId)
  const validationActive = useSelector(selectChartingValidationActive)
  const providers = useProviders()

  const { clientDetails } = useClientDetails(clientIdInContext)

  const [initialized, setInitialized] = useState(false)
  const [healthConcerns, setHealthConcerns] = useState<CodedConceptDto[] | undefined>(undefined)
  const [referredByState, setReferredByState] = useState<string | undefined>(undefined)

  const createdByProvider = useMemo(
    () => providers?.find((each) => each.id === createdBy),
    [createdBy, providers]
  )

  const lastUpdatedByProvider = useMemo(
    () => providers?.find((each) => each.id === lastUpdatedBy),
    [lastUpdatedBy, providers]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const healthConcernOptions = useMemo(() => {
    const retVal: CodedConceptDto[] = []
    const seen = new Set()
    if (healthConcerns) {
      for (let i = 0; i < healthConcerns.length; i++) {
        if (!seen.has(healthConcerns[i].id)) {
          retVal.push(healthConcerns[i])
          seen.add(healthConcerns[i].id)
        }
      }
    }
    if (diagnosis) {
      for (let i = 0; i < diagnosis.length; i++) {
        if (!seen.has(diagnosis[i].id)) {
          retVal.push(diagnosis[i])
          seen.add(diagnosis[i].id)
        }
      }
    }
    return retVal
  }, [diagnosis, healthConcerns])

  const handleChange = useCallback(
    (key: string, value: TReferralTemplateValue) =>
      onChange(key, value, !isTemplateEditor ? loggedInProvider?.id : undefined),
    [isTemplateEditor, loggedInProvider?.id, onChange]
  )

  useEffect(() => {
    setReferredByState(referredBy)
    if (!isTemplateEditor) {
      if (loggedInProvider && loggedInProvider.id) {
        if (createdBy === undefined) handleChange('createdBy', loggedInProvider.id)
        if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
        if (lastUpdatedBy === undefined) handleChange('lastUpdatedBy', loggedInProvider.id)
        if (lastUpdated === undefined) handleChange('lastUpdated', dateNowIsoString())
        if (referredBy === undefined && createdBy === undefined) {
          handleChange('referredBy', loggedInProvider.id)
          setReferredByState(loggedInProvider.id)
        }

        if (appointmentBooked === undefined) handleChange('appointmentBooked', false)
      }
      if (clientId === undefined && clientIdInState) handleChange('clientId', clientIdInState)
      if (state === undefined) handleChange('state', EReferralTemplateStateValue.Draft)
    }
    setInitialized(true)
  }, [
    appointmentBooked,
    clientId,
    clientIdInState,
    createdBy,
    createdOn,
    handleChange,
    isTemplateEditor,
    lastUpdated,
    lastUpdatedBy,
    loggedInProvider,
    referredBy,
    state,
  ])

  useEffect(() => {
    const getHealthConcernsForClient = async (clientId: string) => {
      let healthConcernsToSet: CodedConceptDto[] = []
      const resp = await HealthConcernControllerService.getHealthConcerns(clientId)
      if (resp.content) {
        for (let i = 0; i < resp.content.length; i++) {
          const healthConcern = resp.content[i].healthConcern
          let response: UnPagedCodedConceptDto
          if (healthConcern && healthConcern.codeSystemOid && healthConcern.code) {
            response = await CodedConceptControllerService.getFilteredConceptCodes(
              healthConcern.codeSystemOid,
              healthConcern.code
            )
            healthConcernsToSet = healthConcernsToSet.concat(response.content || [])
          }
        }
        setHealthConcerns(healthConcernsToSet)
      } else setHealthConcerns([])
    }
    if (clientDetails?.id) getHealthConcernsForClient(clientDetails?.id)
  }, [clientDetails])

  if (!initialized && !clientDetails && !healthConcerns) return null

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography component="h1">{t('charting.templates.referral.label')}</Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12}>
          <MISSelectDropdown
            label={t('charting.templates.referral.fields.state')}
            noClearIcon
            onChange={(e) => handleChange('state', e.target.value)}
            options={Object.values(EReferralTemplateStateValue).map((each) => ({
              label: each,
              value: each,
            }))}
            value={Object.values(EReferralTemplateStateValue)?.find((each) => each === state)}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <MISSelectDropdown
            error={validationActive && !sentBy}
            helperText={
              validationActive && !sentBy
                ? t('charting.templates.referral.fields.sent-by-required')
                : undefined
            }
            label={t('charting.templates.referral.fields.sent-by')}
            onChange={(e) => handleChange('sentBy', e.target.value.id)}
            options={
              providers?.map((provider: PersonnelDTO) => {
                return {
                  label: evaluateLabelUtil(provider?.names),
                  value: provider,
                }
              }) || []
            }
            required
            value={providers?.find((each) => each.id === sentBy)}
          />
        </Grid>

        <Box width="100%" />
        <Grid item md={4} sm={12}>
          <Typography sx={{ fontWeight: 'bold' }}>{`${t(
            'charting.templates.referral.fields.client'
          )}${getClientLegalName(clientDetails)}`}</Typography>
        </Grid>
        <Grid item md={4} sm={12}>
          <MISSelectDropdown
            error={validationActive && !referredTo}
            helperText={
              validationActive && !referredTo
                ? t('charting.templates.referral.fields.referred-to-required')
                : undefined
            }
            label={t('charting.templates.referral.fields.referred-to')}
            onChange={(e) => handleChange('referredTo', e.target.value.id)}
            options={
              providers?.map((provider: PersonnelDTO) => {
                return {
                  label: evaluateLabelUtil(provider?.names),
                  value: provider,
                }
              }) || []
            }
            required
            value={providers?.find((each) => each.id === referredTo)}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <MISSelectDropdown
            error={validationActive && !referredByState}
            helperText={
              validationActive && !referredByState
                ? t('charting.templates.referral.fields.referred-by-required')
                : undefined
            }
            label={t('charting.templates.referral.fields.referred-by')}
            onChange={(e) => {
              handleChange('referredBy', e.target.value.id)
              setReferredByState(e.target.value.id || null)
            }}
            options={
              providers?.map((provider: PersonnelDTO) => {
                return {
                  label: evaluateLabelUtil(provider?.names),
                  value: provider,
                }
              }) || []
            }
            required
            value={providers?.find((each) => each.id === referredByState)}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ backgroundColor: global.TEMPLATE_SECTION_BG_COLOR, padding: global.PADDING_XXXS }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {t('charting.templates.referral.fields.referral-summary')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            error={validationActive && !reason}
            helperText={
              validationActive && !reason
                ? t('charting.templates.referral.fields.reason-required')
                : undefined
            }
            label={t('charting.templates.referral.fields.reason')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('reason', e.target.value)}
            required
            value={reason || ''}
          />
        </Grid>
        <Grid item sm={12}>
          <MISSelectMultiDropdown
            label={t('charting.templates.referral.fields.diagnosis')}
            onChange={(e) => handleChange('diagnosis', e.target.value || [])}
            options={healthConcernOptions.map((each: CodedConceptDto) => {
              return {
                label: each.name,
                value: each,
              }
            })}
            value={
              diagnosis?.map((diag) => healthConcernOptions.find((each) => diag.id === each.id)) ||
              []
            }
          />
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            label={t('charting.templates.referral.fields.history')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('history', e.target.value)}
            value={history || ''}
          />
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            label={t('charting.templates.referral.fields.requested-tests')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('requestedTests', e.target.value)}
            value={requestedTests || ''}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Typography>{`${t(
              'charting.templates.referral.fields.requested-consultations'
            )}`}</Typography>
            <FormControlLabel
              control={
                <MISCheckbox
                  checked={!!requestedConsultationFirstAvailable}
                  onChange={(_, checked) =>
                    handleChange('requestedConsultationFirstAvailable', checked)
                  }
                  sx={{ '&.MuiCheckbox-root': { ml: 3, p: 0 } }}
                />
              }
              label={t(
                'charting.templates.referral.fields.requested-consultations-first-available'
              )}
            />
          </Box>
        </Grid>

        <Grid item sm={12}>
          <MISTextField
            label={t('charting.templates.referral.fields.requested-consultations-comment')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('requestedConsultationComment', e.target.value)}
            value={requestedConsultationComment || ''}
          />
        </Grid>
        <Grid item sm={12}>
          <FormControlLabel
            control={
              <MISCheckbox
                checked={!!phiWithheld}
                onChange={(_, checked) => handleChange('phiWithheld', checked)}
              />
            }
            label={t('charting.templates.referral.fields.phi-withheld')}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ backgroundColor: global.TEMPLATE_SECTION_BG_COLOR, padding: global.PADDING_XXXS }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {' '}
              {t('charting.templates.referral.fields.appointment-details')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <MISRadioGroup
            id="booking"
            label=""
            onChange={(event) => handleChange('appointmentBooked', event.target.value === 'BOOKED')}
            options={[
              {
                label: t('charting.templates.referral.fields.appointment-booked'),
                value: 'BOOKED',
              },
              {
                label: t('charting.templates.referral.fields.appointment-not-booked'),
                value: 'NOTBOOKED',
              },
            ]}
            sx={{ mt: -1 }}
            value={appointmentBooked ? 'BOOKED' : appointmentBooked === false ? 'NOTBOOKED' : ''}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <DatePicker
            disablePast
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.referral.fields.appointment-date')}
            onChange={(value) => handleChange('appointmentDate', value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={appointmentDate || null}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <MISTimePicker
            label={t('charting.templates.referral.fields.appointment-time')}
            onChange={(value) => handleChange('appointmentTime', value as string)}
            value={appointmentTime || null}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <MISTextField
            label={t('charting.templates.referral.fields.appointment-location')}
            onChange={(e) => handleChange('appointmentLocation', e.target.value)}
            value={appointmentLocation || ''}
          />
        </Grid>
        {(createdBy || createdOn) && (
          <Grid item sm={12} sx={{ mt: 1 }}>
            {createdBy && createdByProvider && createdOn && (
              <Typography>{`${t(
                'charting.templates.referral.fields.created-by'
              )}${evaluateLabelUtil(createdByProvider.names)} ${isoDateToDisplayFormatWithTime(
                createdOn
              )}`}</Typography>
            )}
            {lastUpdatedBy && lastUpdatedByProvider && lastUpdated && (
              <Typography>{`${t(
                'charting.templates.referral.fields.last-updated-by'
              )}${evaluateLabelUtil(lastUpdatedByProvider.names)} ${isoDateToDisplayFormatWithTime(
                lastUpdated
              )}`}</Typography>
            )}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default ReferralTemplateComponent
