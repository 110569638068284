import { Component } from 'react'
import { dateNowIsoString } from 'common/utils/DateUtils'
import { CodedConceptDto, PersonnelDTO, ProgramTerse } from 'services/openapi'
import ReferralTemplateComponent from './ReferralTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export enum EReferralTemplateStateValue {
  Draft = 'Draft',
  Ready = 'Ready',
  Sent = 'Sent',
  Additional_Info_Requested = 'Additional Info Requested',
  Accepted = 'Accepted',
  Booked = 'Booked',
  Reassigned = 'Reassigned',
  Waitlisted = 'Waitlisted',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Voided = 'Voided',
  Completed = 'Completed',
}

export type TReferralTemplateValue =
  | boolean
  | string
  | CodedConceptDto
  | PersonnelDTO
  | ProgramTerse[]
  | EReferralTemplateStateValue

export type ReferralTemplateState = {
  clientId?: string
  createdBy?: string
  createdOn?: string
  diagnosis?: CodedConceptDto[]
  history?: string
  lastUpdated?: string
  lastUpdatedBy?: string
  phiWithheld?: boolean
  reason?: string
  referredBy?: string
  referredTo?: string
  requestedConsultationComment?: string
  requestedConsultationFirstAvailable?: boolean
  requestedTests?: string
  state?: EReferralTemplateStateValue
  sentBy?: string
  appointmentBooked?: boolean
  appointmentDate?: string
  appointmentTime?: string
  appointmentLocation?: string
}

export type ReferralTemplateProps = { data: ReferralTemplateState }

class ReferralTemplate
  extends Component<ReferralTemplateProps, ReferralTemplateState>
  implements ITemplate
{
  type = 'ReferralTemplate'
  state: ReferralTemplateState

  constructor(props: ReferralTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    appointmentBooked: { label: 'Date', type: ETemplateMetadataType.boolean },
    appointmentDate: { label: 'Date', type: ETemplateMetadataType.Date },
    appointmentLocation: { label: 'Location', type: ETemplateMetadataType.string },
    appointmentTime: { label: 'Time', type: ETemplateMetadataType.string },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    diagnosis: { isArray: true, label: 'Diagnosis', type: ETemplateMetadataType.CodedConceptDto },
    history: { label: 'History', type: ETemplateMetadataType.string },
    lastUpdated: { label: 'Last updated on', type: ETemplateMetadataType.DateTime },
    lastUpdatedBy: { label: 'Last updated by', type: ETemplateMetadataType.ProviderId },
    phiWithheld: { label: 'PHI Withheld', type: ETemplateMetadataType.boolean },
    reason: { label: 'Reason for Referral', required: true, type: ETemplateMetadataType.string },
    referredBy: { label: 'Referred by', required: true, type: ETemplateMetadataType.ProviderId },
    referredTo: { label: 'Referred to', required: true, type: ETemplateMetadataType.ProviderId },
    requestedConsultationComment: {
      label: 'Requested Consultations Comment',
      type: ETemplateMetadataType.string,
    },
    requestedConsultationFirstAvailable: {
      label: 'Requested Consultations First Available',
      type: ETemplateMetadataType.boolean,
    },
    requestedTests: {
      label: 'Requested Tests or Services',
      type: ETemplateMetadataType.string,
    },
    sentBy: { label: 'Sent by', required: true, type: ETemplateMetadataType.ProviderId },
    state: { label: 'Referral Status', type: ETemplateMetadataType.string },
  })

  static getTemplateDisplayName = (): string => 'Referral Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    appointmentBooked: this.state.appointmentBooked,
    appointmentDate: this.state.appointmentDate,
    appointmentLocation: this.state.appointmentLocation,
    appointmentTime: this.state.appointmentTime,
    clientId: this.state.clientId,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    diagnosis: this.state.diagnosis,
    history: this.state.history,
    lastUpdated: this.state.lastUpdated,
    lastUpdatedBy: this.state.lastUpdatedBy,
    phiWithheld: this.state.phiWithheld,
    reason: this.state.reason,
    referredBy: this.state.referredBy,
    referredTo: this.state.referredTo,
    requestedConsultationComment: this.state.requestedConsultationComment,
    requestedConsultationFirstAvailable: this.state.requestedConsultationFirstAvailable,
    requestedTests: this.state.requestedTests,
    sentBy: this.state.sentBy,
    state: this.state.state,
  })

  handleChange = (key: string, value: TReferralTemplateValue, lastUpdatedBy?: string) =>
    this.setState({
      ...this.state,
      [key]: value,
      lastUpdated: dateNowIsoString(),
      lastUpdatedBy,
    })

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return <ReferralTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default ReferralTemplate
