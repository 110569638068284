import { useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { IconButton } from '@mui/material'
import { selectChartingCurrentTemplate } from 'store/selectors/charting'

type EditButtonProps = {
  onDelete: () => void
  onEdit: () => void
}

const EditButton = ({ onDelete, onEdit }: EditButtonProps) => {
  const currTemplate = useSelector(selectChartingCurrentTemplate)

  return (
    <>
      <IconButton
        onClick={onEdit}
        onMouseDown={(e) => e.stopPropagation()}
        sx={{ position: 'absolute', right: 4, top: -8 }}
      >
        <ModeEditIcon fontSize="small" />
      </IconButton>
      {(!currTemplate?.id || currTemplate?.state === 'DRAFT') && (
        <IconButton
          onClick={onDelete}
          onMouseDown={(e) => e.stopPropagation()}
          sx={{ position: 'absolute', right: 4, top: 16 }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </>
  )
}

export default EditButton
