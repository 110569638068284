import { atom } from 'recoil'
import { ChartingEntryDTO, CodedRef, FormSchemaDTO } from 'services/openapi'

export type ChartingDataType = {
  id?: string
  serviceDateTime?: string
  duration?: number
  status?: CodedRef
  statusComment?: string
  statusReason?: CodedRef
  primaryProvider?: string
}

export const chartingEntriesState = atom<ChartingEntryDTO[] | undefined>({
  default: undefined,
  key: 'chartingEntriesState',
})

export const myTemplatesAtom = atom<FormSchemaDTO[] | undefined>({
  default: undefined,
  key: 'myTemplatesAtom',
})

export const chartingEditorProgressState = atom<ChartingEntryDTO | undefined>({
  default: undefined,
  key: 'chartingEditorProgressState',
})

export const chartingFieldsProgressState = atom<ChartingDataType | undefined>({
  default: undefined,
  key: 'chartingFieldsProgressState',
})

export const isDisplayInProgressState = atom<boolean>({
  default: false,
  key: 'isDisplayInProgressState',
})

export const isTemplateUpdatedState = atom<boolean>({
  default: false,
  key: 'isTemplateUpdatedState',
})
