import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TEMPLATE_TYPE } from 'modules/charting/components/template-management/TemplateEditor'
import { FormSchemaDTO } from 'services/openapi'

export type activeTabData = {
  templateId: string
  templateName: string
  filters?: string[]
}
export interface ChartingState {
  activeHistoricTabs: activeTabData[]
  clientIdInContext?: string
  currentTemplate?: FormSchemaDTO
  myTemplates?: FormSchemaDTO[]
  preview: boolean
  publicTemplates?: FormSchemaDTO[]
  validationActive: boolean
}

const initialState: ChartingState = {
  activeHistoricTabs: [],
  preview: false,
  validationActive: false,
}

export const chartingSlice = createSlice({
  initialState,
  name: 'charting',
  reducers: {
    setActiveHistoricTabs: (state, action: PayloadAction<activeTabData[]>) => {
      state.activeHistoricTabs = action.payload
    },
    setClientIdInContext: (state, action: PayloadAction<string | undefined>) => {
      state.clientIdInContext = action.payload
    },
    setCurrentTemplate: (state, action: PayloadAction<FormSchemaDTO | undefined>) => {
      state.currentTemplate = action.payload
    },
    setCurrentTemplateType: (state, action: PayloadAction<TEMPLATE_TYPE | undefined>) => {
      state.currentTemplate = { ...state.currentTemplate, templateType: action.payload }
    },
    setMyTemplates: (state, action: PayloadAction<FormSchemaDTO[]>) => {
      state.myTemplates = action.payload
    },
    setPreview: (state, action: PayloadAction<boolean>) => {
      state.preview = action.payload
    },
    setPublicTemplates: (state, action: PayloadAction<FormSchemaDTO[]>) => {
      state.publicTemplates = action.payload
    },
    setValidationActive: (state, action: PayloadAction<boolean>) => {
      state.validationActive = action.payload
    },
  },
})

export const {
  setActiveHistoricTabs,
  setClientIdInContext,
  setCurrentTemplate,
  setCurrentTemplateType,
  setMyTemplates,
  setPreview,
  setPublicTemplates,
  setValidationActive,
} = chartingSlice.actions

export default chartingSlice.reducer
