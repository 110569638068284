import { Component, createRef } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Fab, Stack, Tooltip } from '@mui/material'
import * as markerjs2 from 'markerjs2'
import MISButton from 'common/components/MISButton'
import { ITemplate } from '../../blots/TemplateBlot'
import { convertToBase64 } from '../image-marker-template/ImageMarkerTemplate'

import './ImageTemplate.scss'

export type ImageTemplateState = {
  dataUrl?: string
  markerState?: markerjs2.MarkerAreaState
  image?: string
}

type ImageTemplateProps = { data?: ImageTemplateState }

export default class ImageTemplate
  extends Component<ImageTemplateProps, ImageTemplateState>
  implements ITemplate
{
  type = 'ImageTemplate'
  state: ImageTemplateState = {}
  imgRef = createRef<HTMLImageElement>()
  markerArea: markerjs2.MarkerArea | null = null

  constructor(props: ImageTemplateProps) {
    super(props)
    this.state = { ...props.data }
    this.imgRef = createRef()
  }

  getData = () => {
    return {
      dataUrl: this.state.dataUrl,
      image: this.state.image,
      markerState: this.state.markerState,
    }
  }

  handleFileUpload = async (files: FileList) => {
    const file = files[0]
    try {
      const base64 = await convertToBase64(file)
      this.setState({ ...this.state, image: base64 as string })
    } catch (e) {
      // TODO
    }
  }

  handleShowMarkerArea = () => {
    if (this.imgRef.current) {
      // create a marker.js MarkerArea
      this.markerArea = new markerjs2.MarkerArea(this.imgRef.current)
      this.markerArea.settings.displayMode = 'popup'
      this.markerArea.settings.popupMargin = 100
      this.markerArea.uiStyleSettings.zIndex = '1500'
      // attach an event handler to assign annotated image back to our image element
      this.markerArea.addEventListener('render', (event) => {
        if (this.imgRef.current) {
          const existingMarkerState = this.state.markerState?.markers || []
          const newMarkerState = existingMarkerState.concat(event.state.markers)
          this.setState({
            dataUrl: event.dataUrl,
            markerState: { ...event.state, markers: newMarkerState },
          })
        }
      })
      this.markerArea?.show()
    }
  }

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return (
      <Box>
        {this.state.image ? (
          <Stack direction="row" spacing={1}>
            <Box sx={{ cursor: 'default', maxHeight: 550, maxWidth: 612 }}>
              <img
                alt="custom"
                crossOrigin="anonymous"
                onClick={this.handleShowMarkerArea}
                ref={this.imgRef}
                src={this.state.dataUrl || this.state.image}
              />
            </Box>
            <Stack spacing={1}>
              <Tooltip title="Remove image">
                <Fab
                  onClick={() =>
                    this.setState({
                      dataUrl: undefined,
                      image: undefined,
                      markerState: undefined,
                    })
                  }
                  size="small"
                >
                  <DeleteIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Clear annotation">
                <Fab
                  onClick={() => this.setState({ dataUrl: undefined, markerState: undefined })}
                  size="small"
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>
            </Stack>
          </Stack>
        ) : (
          <Box
            className="upload-container"
            contentEditable
            onDrop={(e) => {
              e.preventDefault()
              e.dataTransfer.files && this.handleFileUpload(e.dataTransfer.files)
            }}
            sx={{ border: '1px solid black', height: 550, width: 612 }}
          >
            <MISButton
              color="primary"
              component="label"
              role={undefined}
              size="large"
              startIcon={<AddCircleOutline />}
              tabIndex={-1}
              variant="contained"
            >
              Upload image
              <input
                accept=".jpeg, .png, .jpg"
                className="upload-input"
                onChange={(e) => e.target.files && this.handleFileUpload(e.target.files)}
                type="file"
              />
            </MISButton>
          </Box>
        )}
      </Box>
    )
  }
}
