import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, IconButton, ListItem, ListItemIcon, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import { useRecoilState } from 'recoil'
import logo from 'assets/images/mis-logo.png'
import poweredByLogo from 'assets/images/powered_by_musti.svg'
import MISAdminIcon from 'common/components/icons/MISAdminIcon'
import MISBlankCanvasIcon from 'common/components/icons/MISBlankCanvasIcon'
import MISClientSearchIcon from 'common/components/icons/MISClientSearchIcon'
import MISDashboardIcon from 'common/components/icons/MISDashboardIcon'
import MISProfileIcon from 'common/components/icons/MISProfileIcon'
import MISToDoIcon from 'common/components/icons/MISToDoIcon'
import global from 'common/styles/global.scss'
import MISNavigationItem from 'core/components/navigation/MISNavigationItem'
import { getClientFullName } from 'modules/shared/clientUtils'
import { isDisplayInProgressState } from 'recoil/charting'
import { selectClientDetails } from 'store/selectors/client'
import { MISNavigationState } from './MISNavigationState'

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ open, theme }) => ({
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      boxSizing: 'border-box',
      minWidth: 170,
      position: 'relative',
      transition: theme.transitions.create('width', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      whiteSpace: 'nowrap',
      ...(!open && {
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          duration: theme.transitions.duration.leavingScreen,
          easing: theme.transitions.easing.sharp,
        }),
        width: theme.spacing(7),
      }),
    },
  })
)

const Drawer = () => {
  const clientDetails = useSelector(selectClientDetails)
  const { t } = useTranslation('common')
  const [navItemSelected, setNavItemSelected] = useRecoilState(MISNavigationState)
  const [prevNavItem, setPrevNavItem] = useState('')
  const [isDisplayInProgress] = useRecoilState(isDisplayInProgressState)
  const tenantId = localStorage.getItem('tenantId')

  const [flipped, setFlipped] = useState(-1)

  const getClientName = useCallback(() => {
    if (clientDetails && clientDetails.names) {
      const preferredName = clientDetails.names.find((o) => o.preferredName)
      if (preferredName) {
        return `${preferredName.firstName} ${preferredName.lastName}`
      }
    }
    return ''
  }, [clientDetails])

  const handleFlip = useCallback((index) => {
    setFlipped(index)
  }, [])

  useEffect(() => {
    if (navItemSelected === 'navigation.left-nav-menu.charting.blank-canvas') {
      setFlipped(-1)
    }
  }, [navItemSelected])

  useEffect(() => {
    if (clientDetails?.id) {
      setFlipped(0)
    }
  }, [clientDetails])

  return (
    <StyledDrawer open variant="permanent">
      <Grid container sx={{ display: 'block' }}>
        <Grid item>
          <List className="list">
            <ListItem>
              <Box
                component="img"
                src={logo}
                sx={{
                  height: '100px',
                  marginLeft: '18px',
                  width: '100px',
                }}
              />
            </ListItem>
            {flipped === -1 && (
              <>
                <MISNavigationItem
                  icon={<MISBlankCanvasIcon />}
                  label={t('navigation.left-nav-menu.blank-canvas')}
                  name="navigation.left-nav-menu.charting.blank-canvas"
                  route="charting"
                />
                <MISNavigationItem
                  icon={<MISClientSearchIcon />}
                  label={t('navigation.left-nav-menu.client-search')}
                  name="navigation.left-nav-menu.client-search"
                  route="clients"
                />
                <MISNavigationItem
                  icon={<MISDashboardIcon />}
                  label={t('navigation.left-nav-menu.admin.dashboard')}
                  name="navigation.left-nav-menu.admin.dashboard"
                  route="admin/dashboard"
                />
                <MISNavigationItem
                  icon={<MISToDoIcon />}
                  label={t('navigation.left-nav-menu.to-do')}
                  name="navigation.left-nav-menu.to-do"
                  route="admin/to-do"
                />
                <MISNavigationItem
                  icon={<MISAdminIcon />}
                  label={t('navigation.left-nav-menu.admin.admin')}
                  name="navigation.left-nav-menu.admin.admin"
                  onSelect={() => {
                    handleFlip(1)
                    setTimeout(() => {
                      setNavItemSelected('navigation.left-nav-menu.admin.providers-staff')
                    }, 10)
                  }}
                  route=""
                />
                <Divider />
                {clientDetails?.id && (
                  <MISNavigationItem
                    icon={<MISProfileIcon />}
                    label={getClientName()}
                    name={getClientName()}
                    onSelect={() => {
                      handleFlip(0)
                      setTimeout(() => {
                        setNavItemSelected(
                          prevNavItem || 'navigation.left-nav-menu.client.dashboard'
                        )
                        setPrevNavItem('')
                      }, 10)
                    }}
                    route=""
                  />
                )}
              </>
            )}
            {flipped === 0 && (
              <Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <IconButton
                    aria-label="back"
                    id="back-button"
                    onClick={() => {
                      handleFlip(-1)
                      setPrevNavItem(navItemSelected)
                    }}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: global.FONT_BODY_REGULAR, lineHeight: 3 }}>
                    Main Menu
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: global.FONT_BODY_REGULAR,
                    fontWeight: global.FONT_WEIGHT_SEMIBOLD,
                    lineHeight: 3,
                    marginLeft: 2,
                  }}
                >
                  {getClientFullName(clientDetails)}
                </Typography>
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.client.client-profile')}
                  name="navigation.left-nav-menu.client.client-profile"
                  route={`clients/client-record/${clientDetails?.id}`}
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.client.dashboard')}
                  name="navigation.left-nav-menu.client.dashboard"
                  route={`clients/client-record/${clientDetails?.id}/dashboard`}
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.client.charting')}
                  name="navigation.left-nav-menu.client.charting"
                  route={`clients/client-record/${clientDetails?.id}/charting`}
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.client.immunizations')}
                  name="navigation.left-nav-menu.client.immunizations"
                  route={`clients/client-record/${clientDetails?.id}/immunizations`}
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.client.health-concerns')}
                  name="navigation.left-nav-menu.client.health-concerns"
                  route={`clients/client-record/${clientDetails?.id}/health-concerns`}
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.to-do')}
                  name="navigation.left-nav-menu.to-do"
                  route={`clients/client-record/${clientDetails?.id}/to-do`}
                />
                <MISNavigationItem
                  isExternal
                  label={t('navigation.left-nav-menu.scheduler')}
                  name="navigation.left-nav-menu.scheduler"
                  route={`cambianscheduler/client/${clientDetails?.fileNumber}?tenant=${tenantId}`}
                />
                {isDisplayInProgress && (
                  <MISNavigationItem
                    label={t('navigation.left-nav-menu.client.charting-in-progress')}
                    name="navigation.left-nav-menu.client.charting.in-progress"
                    route={`clients/client-record/${clientDetails?.id}/in-progress`}
                  />
                )}
              </Box>
            )}
            {flipped === 1 && (
              <Box>
                <Box sx={{ display: 'inline-flex' }}>
                  <IconButton
                    aria-label="back"
                    id="back-button"
                    onClick={() => {
                      handleFlip(-1)
                    }}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: global.FONT_BODY_REGULAR, lineHeight: 3 }}>
                    Main Menu
                  </Typography>
                </Box>
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.providers-staff')}
                  name="navigation.left-nav-menu.admin.providers-staff"
                  route="provider-staff-record/search"
                />
                {/* commented out as per IDHE-5047 */}
                {/* <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.teams')}
                  name="navigation.left-nav-menu.admin.teams"
                  route="admin/teams"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.supervisor-signoff')}
                  name="navigation.left-nav-menu.admin.supervisor-signoff"
                  route="domain-selection"
                /> */}
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.governance-agencies')}
                  name="navigation.left-nav-menu.admin.governance-agencies"
                  route="admin/governance-agencies"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.alerts')}
                  name="navigation.left-nav-menu.admin.alerts"
                  route="admin/alerts"
                />
                {/* commented out as per IDHE-5047 */}
                {/* <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.client-merge')}
                  name="navigation.left-nav-menu.admin.client-merge"
                  route="domain-selection"
                /> */}
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.template-management')}
                  name="navigation.left-nav-menu.admin.template-management"
                  route="admin/template-management"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.user-provisioning')}
                  name="navigation.left-nav-menu.admin.user-provisioning"
                  route="admin/user-provisioning"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.programs')}
                  name="navigation.left-nav-menu.admin.programs"
                  route="admin/program/programs"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.business-lines')}
                  name="navigation.left-nav-menu.admin.business-lines"
                  route="admin/program/business-lines"
                />
                <MISNavigationItem
                  label={t('navigation.left-nav-menu.admin.services')}
                  name="navigation.left-nav-menu.admin.services"
                  route="admin/program/charting-services"
                />
              </Box>
            )}
            <ListItem>
              <ListItemIcon sx={{ minWidth: 'auto' }}>
                <Box
                  component="img"
                  src={poweredByLogo}
                  sx={{
                    height: '70px',
                    marginLeft: '34px',
                    width: '70px',
                  }}
                />
              </ListItemIcon>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </StyledDrawer>
  )
}

export default Drawer
