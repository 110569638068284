import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ImmunizationScheduleDTO, ImmunizationShotDTO, ScheduleSequenceDTO } from 'services/openapi'

export interface ImmunizationState {
  immunizationSchedules?: ImmunizationScheduleDTO[]
  immunizationScheduleSequences?: ScheduleSequenceDTO[]
  immunizationShots?: ImmunizationShotDTO[]
}

const initialState: ImmunizationState = {}

export const immunizationSlice = createSlice({
  initialState,
  name: 'immunization',
  reducers: {
    setImmunizationSchedules: (
      state,
      action: PayloadAction<ImmunizationScheduleDTO[] | undefined>
    ) => {
      state.immunizationSchedules = action.payload
    },
    setImmunizationScheduleSequences: (
      state,
      action: PayloadAction<ScheduleSequenceDTO[] | undefined>
    ) => {
      state.immunizationScheduleSequences = action.payload
    },
    setImmunizationShots: (state, action: PayloadAction<ImmunizationShotDTO[] | undefined>) => {
      state.immunizationShots = action.payload
    },
  },
})

export const { setImmunizationSchedules, setImmunizationScheduleSequences, setImmunizationShots } =
  immunizationSlice.actions

export default immunizationSlice.reducer
