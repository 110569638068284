import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '..'

export const selectImmunizationShots = createSelector(
  [(state: RootState) => state.immunization],
  (immunization) => immunization.immunizationShots ?? [] //.filter((ims) => !ims.isTerminated)
)

export const selectImmunizationSchedules = createSelector(
  [(state: RootState) => state.immunization],
  (immunization) => immunization.immunizationSchedules ?? [] //.filter((ims) => !ims.isTerminated)
)

export const selectImmunizationScheduleSequences = createSelector(
  [(state: RootState) => state.immunization],
  (immunization) => immunization.immunizationScheduleSequences ?? [] //.filter((ims) => !ims.isTerminated)
)
