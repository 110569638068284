import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Box, Grid, Stack, Typography } from '@mui/material'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import {
  dateNowIsoString,
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormatWithTime,
} from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { PersonnelDTO } from 'services/openapi'
import {
  IMMUNIZATION_ADMINISTRATION_SITE,
  IMMUNIZATION_CONSENT_TYPE,
  IMMUNIZATION_DOSAGE_UNIT,
  IMMUNIZATION_ROUTE_OF_ADMINISTRATION,
  IMMUNIZATION_SOURCE_OF_INFORMATION,
} from 'services/terminologyConstants'
import { selectChartingValidationActive } from 'store/selectors/charting'
import {
  selectImmunizationSchedules,
  selectImmunizationScheduleSequences,
  selectImmunizationShots,
} from 'store/selectors/immunization'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import { ImmunizationTemplateState, TImmunizationTemplateValue } from './ImmunizationTemplate'

type ImmunizationTemplateComponentProps = ImmunizationTemplateState & {
  onChange: (key: string, value: TImmunizationTemplateValue) => void
}

const ImmunizationTemplateComponent = ({
  administeringProviderId,
  administeringProviderName,
  agent,
  consent,
  createdBy,
  createdOn,
  date,
  dosage,
  doseId,
  doseNumber,
  immunizationId,
  immunizationName,
  lotExpiryDate,
  lotNumber,
  note,
  onChange,
  route,
  scheduleId,
  scheduleName,
  site,
  sourceOfInformation,
  units,
}: ImmunizationTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const userId = useSelector(selectUserId)
  const terminology = useSelector(selectTerminology)
  const immunizationSchedules = useSelector(selectImmunizationSchedules)
  const immunizationScheduleSequences = useSelector(selectImmunizationScheduleSequences)
  const immunizationShots = useSelector(selectImmunizationShots)
  const validationActive = useSelector(selectChartingValidationActive)
  const providers = useProviders()
  const [initialized, setInitialized] = useState(false)
  const [isHistorical, setIsHistorical] = useState<boolean>(false)

  const [administeringProviderIdState, setAdministeringProviderIdState] = useState<
    string | undefined
  >(undefined)
  const [immunizationIdState, setImmunizationIdState] = useState<string | undefined>(immunizationId)
  const [agentState, setAgentState] = useState(agent)
  const [doseIdState, setDoseIdState] = useState(doseId)
  const [dosageState, setDosageState] = useState(dosage)
  const [routeState, setRouteState] = useState(route)
  const [siteState, setSiteState] = useState(site)
  const [unitsState, setUnitsState] = useState(units)

  const sourceOfInfoOpts = useMemo(
    () =>
      terminology.find((term) => term.setName === IMMUNIZATION_SOURCE_OF_INFORMATION)?.value || [],
    [terminology]
  )

  const createdByProvider = useMemo(
    () => providers?.find((each) => each.id === createdBy),
    [createdBy, providers]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const adminSiteOpts = useMemo(
    () =>
      terminology.find((term) => term.setName === IMMUNIZATION_ADMINISTRATION_SITE)?.value || [],
    [terminology]
  )

  const routeOfAdminOpts = useMemo(
    () =>
      terminology.find((term) => term.setName === IMMUNIZATION_ROUTE_OF_ADMINISTRATION)?.value ||
      [],
    [terminology]
  )

  const dosageUnitOpts = useMemo(
    () => terminology.find((term) => term.setName === IMMUNIZATION_DOSAGE_UNIT)?.value || [],
    [terminology]
  )

  const immunizationConsentOpts = useMemo(
    () => terminology.find((term) => term.setName === IMMUNIZATION_CONSENT_TYPE)?.value || [],
    [terminology]
  )

  const handleChange = useCallback(
    (key: string, value: any) => {
      if (key === 'immunizationId') {
        console.log('immunizationId', value)
        const shot = immunizationShots.find((shot) => shot.id === value)
        setImmunizationIdState(value)
        setDoseIdState(undefined)
        onChange('doseId', undefined)
        setDosageState(shot?.defaultDosage || undefined)
        onChange('dosage', shot?.defaultDosage || undefined)

        const defaultRoute = routeOfAdminOpts.find(
          (each) =>
            each.code === shot?.defaultRoute?.code &&
            each.codeSystemOid === shot?.defaultRoute?.codeSystemOid
        )
        setRouteState(defaultRoute || undefined)
        onChange('route', defaultRoute || undefined)

        const defaultSite = adminSiteOpts.find(
          (each) =>
            each.code === shot?.defaultSite?.code &&
            each.codeSystemOid === shot?.defaultSite?.codeSystemOid
        )
        setSiteState(defaultSite || undefined)
        onChange('site', defaultSite || undefined)

        const defaultUnit = dosageUnitOpts.find(
          (each) =>
            each.code === shot?.defaultDosageUnit?.code &&
            each.codeSystemOid === shot?.defaultDosageUnit?.codeSystemOid
        )
        setUnitsState(defaultUnit || undefined)
        onChange('units', defaultUnit || undefined)
      } else if (key === 'agent') {
        setAgentState(value)
      } else if (key === 'doseId') {
        setDoseIdState(value)
      } else if (key === 'dosage') {
        setDosageState(value)
      } else if (key === 'route') {
        setRouteState(value)
      } else if (key === 'site') {
        setSiteState(value)
      } else if (key === 'units') {
        setUnitsState(value)
      }
      onChange(key, value)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps,
    [
      onChange,
      immunizationIdState,
      doseIdState,
      dosageState,
      agentState,
      dosageState,
      routeState,
      siteState,
      unitsState,
    ]
  )

  useEffect(() => {
    setAdministeringProviderIdState(administeringProviderId)
    if (!isTemplateEditor) {
      if (loggedInProvider && loggedInProvider.id) {
        if (createdBy === undefined) handleChange('createdBy', loggedInProvider.id)
        if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
        if (administeringProviderId === undefined && createdBy === undefined) {
          handleChange('administeringProviderId', loggedInProvider.id)
          handleChange(
            'administeringProviderName',
            evaluateLabelUtil(
              providers?.find((provider: PersonnelDTO) => provider.id === loggedInProvider.id)
                ?.names
            )
          )
          setAdministeringProviderIdState(loggedInProvider.id)
        }
      }
    }
    setInitialized(true)
  }, [
    createdBy,
    createdOn,
    handleChange,
    isTemplateEditor,
    loggedInProvider,
    administeringProviderId,
    providers,
  ])

  useEffect(() => {
    if (!isTemplateEditor) {
      if (
        sourceOfInformation?.code === 'PPGUNDOC' ||
        sourceOfInformation?.code === 'IMMSIMPORT' ||
        sourceOfInformation?.code === 'PROVIDERUNDOC' ||
        sourceOfInformation?.code === 'TRANSCRIBED'
      ) {
        setIsHistorical(true)
        if (administeringProviderIdState != null) {
          handleChange('administeringProviderId', '')
          handleChange('administeringProviderName', '')
          setAdministeringProviderIdState('')
        }
      } else {
        setIsHistorical(false)
        handleChange('administeringProviderId', loggedInProvider?.id || '')
        handleChange(
          'administeringProviderName',
          evaluateLabelUtil(
            providers?.find((provider: PersonnelDTO) => provider.id === loggedInProvider?.id)?.names
          ) || ''
        )
        setAdministeringProviderIdState(loggedInProvider?.id || '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceOfInformation, loggedInProvider, isTemplateEditor, handleChange, providers])

  useEffect(() => {
    if (
      initialized &&
      !isHistorical &&
      immunizationShots.find((shot) => shot.id === immunizationIdState)?.historicalUseOnly
    ) {
      handleChange('agent', undefined)
      handleChange('immunizationId', undefined)
      handleChange('immunizationName', undefined)
    }
  }, [isHistorical, handleChange, immunizationIdState, immunizationShots, initialized])

  if (!initialized) return null

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography component="h2">{t('charting.templates.immunization.label')}</Typography>
          </Stack>
        </Grid>
        <Box width="100%" />
        <Grid item md={5} sm={12}>
          <MISSelectDropdown
            error={validationActive && !sourceOfInformation}
            helperText={
              validationActive && !sourceOfInformation
                ? t('charting.templates.immunization.fields.source-of-information-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.source-of-information')}
            noClearIcon
            onChange={(e) => handleChange('sourceOfInformation', e.target.value)}
            options={sourceOfInfoOpts.map((each) => ({ label: each.name as string, value: each }))}
            required
            value={sourceOfInfoOpts.find((source) => source.id === sourceOfInformation?.id)}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <DatePicker
            disablePast={!isHistorical}
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.immunization.fields.date')}
            onChange={(value) => handleChange('date', value || '')}
            renderInput={(props) => (
              <MISTextField
                {...props}
                error={validationActive && !date}
                helperText={
                  validationActive && !sourceOfInformation
                    ? t('charting.templates.immunization.fields.source-of-information-required')
                    : undefined
                }
                required={!isHistorical}
              />
            )}
            value={date || null}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <MISSelectDropdown
            label={t('charting.templates.immunization.fields.consent')}
            noClearIcon
            onChange={(e) => handleChange('consent', e.target.value || [])}
            options={immunizationConsentOpts.map((each) => ({
              label: each.name as string,
              value: each,
            }))}
            value={immunizationConsentOpts.find((each) => each.id === consent?.id) || ''}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          {isHistorical ? (
            <MISTextField
              label={t('charting.templates.immunization.fields.administering-provider')}
              onChange={(e) => handleChange('administeringProviderName', e.target.value)}
              value={administeringProviderName || ''}
            />
          ) : (
            <MISSelectDropdown
              error={validationActive && !isHistorical && !administeringProviderIdState}
              helperText={
                validationActive && !isHistorical && !administeringProviderIdState
                  ? t('charting.templates.immunization.fields.administering-provider-required')
                  : undefined
              }
              label={t('charting.templates.immunization.fields.administering-provider')}
              onChange={(e) => {
                handleChange('administeringProviderId', e.target.value?.id || undefined)
                handleChange(
                  'administeringProviderName',
                  evaluateLabelUtil(
                    providers?.find((provider: PersonnelDTO) => provider.id === e.target.value?.id)
                      ?.names
                  ) || undefined
                )
                setAdministeringProviderIdState(e.target.value?.id || undefined)
              }}
              options={
                providers?.map((provider: PersonnelDTO) => {
                  return {
                    label: evaluateLabelUtil(provider?.names),
                    value: provider,
                  }
                }) || []
              }
              required={!isHistorical}
              value={
                providers?.find((each) => each.id === administeringProviderIdState) || undefined
              }
            />
          )}
        </Grid>
        <Grid item md={6} sm={12} />
        <Grid item md={6} sm={12}>
          <MISAutocomplete
            clearOnBlur={false}
            error={validationActive && !immunizationId}
            getOptionLabel={(option) =>
              immunizationShots.find((shot) => shot.id === option.id)?.name || ''
            }
            helperText={
              validationActive && !isHistorical && !immunizationId
                ? t('charting.templates.immunization.fields.immunizationId-required')
                : undefined
            }
            isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
            label={t('charting.templates.immunization.fields.immunizationId')}
            onChange={(e) => {
              const foundShot = e?.id ? immunizationShots.find((shot) => shot.id === e.id) : null
              handleChange('agent', foundShot?.agent || undefined)
              handleChange('immunizationId', foundShot?.id || undefined)
              handleChange('immunizationName', foundShot?.name || undefined)
            }}
            options={immunizationShots
              .filter((each) => (isHistorical ? true : !each.historicalUseOnly))
              .map((each) => ({ id: each.id }))}
            required
            value={immunizationIdState ? { id: immunizationIdState } : ''}
          />
        </Grid>
        <Grid item md={6} sm={12} />
        <Grid item md={7} sm={12}>
          <MISSelectDropdown
            disabled={!immunizationIdState}
            error={validationActive && !isHistorical && !scheduleId}
            helperText={
              validationActive && !isHistorical && !scheduleId
                ? t('charting.templates.immunization.fields.schedule-and-dose-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.schedule-and-dose')}
            noClearIcon
            onChange={(e) => {
              const foundSeq = immunizationScheduleSequences.find(
                (seq) => seq.id === e?.target.value
              )
              handleChange('doseId', e.target.value)
              handleChange('doseNumber', foundSeq?.doseNumber)
              const foundSch = immunizationSchedules.find((schedule) =>
                schedule.sequences?.some((seq) => seq.id === foundSeq?.id)
              )
              handleChange('scheduleId', foundSch?.id)
              handleChange('scheduleName', foundSch?.name)
            }}
            options={immunizationScheduleSequences
              .filter(
                (each) =>
                  each.agent?.code === agentState?.code &&
                  each.agent?.codeSystemOid === agentState?.codeSystemOid
              )
              .map((each) => {
                const schedule = immunizationSchedules.find((schedule) =>
                  schedule.sequences?.some((seq) => seq.id === each?.id)
                )
                return { label: `${schedule?.name} - Dose ${each.doseNumber}`, value: each?.id }
              })}
            required={!isHistorical}
            value={doseIdState ? doseIdState : undefined}
          />
        </Grid>

        <Grid item md={2} sm={12}>
          <MISTextField
            disabled={!immunizationIdState}
            error={validationActive && !isHistorical && !dosageState}
            helperText={
              validationActive && !isHistorical && !dosageState
                ? t('charting.templates.immunization.fields.dosage-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.dosage')}
            onChange={(e) => handleChange('dosage', e.target.value || '')}
            required={!isHistorical}
            value={dosageState || ''}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <MISSelectDropdown
            disabled={!immunizationIdState}
            error={validationActive && !isHistorical && !unitsState}
            helperText={
              validationActive && !isHistorical && !unitsState
                ? t('charting.templates.immunization.fields.units-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.units')}
            noClearIcon
            onChange={(e) => {
              handleChange('units', e.target.value || [])
            }}
            options={dosageUnitOpts.map((each) => ({ label: each.name as string, value: each }))}
            required={!isHistorical}
            value={dosageUnitOpts.find((each) => unitsState?.id === each.id) || undefined}
          />
        </Grid>
        <Grid item md={8} sm={12}>
          <MISSelectDropdown
            disabled={!immunizationIdState}
            error={validationActive && !isHistorical && !siteState}
            helperText={
              validationActive && !isHistorical && !siteState
                ? t('charting.templates.immunization.fields.site-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.site')}
            noClearIcon
            onChange={(e) => handleChange('site', e.target.value || [])}
            options={adminSiteOpts.map((each) => ({ label: each.name as string, value: each }))}
            required={!isHistorical}
            value={adminSiteOpts.find((each) => siteState?.id === each.id) || undefined}
          />
        </Grid>
        <Grid item md={4} sm={12}>
          <MISSelectDropdown
            disabled={!immunizationIdState}
            error={validationActive && !isHistorical && !routeState}
            helperText={
              validationActive && !isHistorical && !routeState
                ? t('charting.templates.immunization.fields.route-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.route')}
            noClearIcon
            onChange={(e) => handleChange('route', e.target.value || [])}
            options={routeOfAdminOpts.map((each) => ({ label: each.name as string, value: each }))}
            required={!isHistorical}
            value={routeOfAdminOpts.find((each) => routeState?.id === each.id) || undefined}
          />
        </Grid>
        <Grid item md={2} sm={12}>
          <MISTextField
            error={validationActive && !isHistorical && !lotNumber}
            helperText={
              validationActive && !isHistorical && !lotNumber
                ? t('charting.templates.immunization.fields.lot-number-required')
                : undefined
            }
            label={t('charting.templates.immunization.fields.lot-number')}
            onChange={(e) => handleChange('lotNumber', e.target.value)}
            required={!isHistorical}
            value={lotNumber || ''}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <DatePicker
            disablePast={!isHistorical}
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.immunization.fields.lot-expiry-date')}
            onChange={(value) => handleChange('lotExpiryDate', value || '')}
            renderInput={(props) => <MISTextField {...props} />}
            value={lotExpiryDate || null}
          />
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            label={t('charting.templates.immunization.fields.note')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('note', e.target.value)}
            value={note || ''}
          />
        </Grid>
        {(createdBy || createdOn) && (
          <Grid item sm={12}>
            {createdBy && createdByProvider && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-by'
              )}${evaluateLabelUtil(createdByProvider.names)}`}</Typography>
            )}
            {createdOn && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-on'
              )}${isoDateToDisplayFormatWithTime(createdOn)}`}</Typography>
            )}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default ImmunizationTemplateComponent
