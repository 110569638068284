import { Component } from 'react'
import VitalsTemplateComponent from './VitalsTemplateComponent'
import { ETemplateMetadataType, ITemplate, ITemplateMetadata } from '../../blots/TemplateBlot'

export type VitalsTemplateState = {
  createdBy?: string
  createdOn?: string
  diastolic?: number
  systolic?: number
}

type VitalsTemplateProps = { data?: VitalsTemplateState }

class VitalsTemplate
  extends Component<VitalsTemplateProps, VitalsTemplateState>
  implements ITemplate
{
  type = 'VitalsTemplate'

  constructor(props: VitalsTemplateProps) {
    super(props)
    this.state = { ...this.state, ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    diastolic: { label: 'Diastolic', type: ETemplateMetadataType.number },
    systolic: { label: 'Systolic', type: ETemplateMetadataType.number },
  })

  static getTemplateDisplayName = (): string => 'Vitals Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({ ...this.state })

  handleChange = (key: string, value: number | string) =>
    this.setState({ ...this.state, [key]: value })

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  render() {
    return <VitalsTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default VitalsTemplate
