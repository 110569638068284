import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { Grid, Stack, Typography } from '@mui/material'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISSelectMultiDropdown from 'common/components/form/MISSelectMultiDropdown'
import MISTextField from 'common/components/form/MISTextField'
import {
  dateNowIsoString,
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormatWithTime,
} from 'common/utils/DateUtils'
import useProviders from 'modules/shared/hooks/useProviders'
import { evaluateLabelUtil } from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { PersonnelDTO, ProgramTerse } from 'services/openapi'
import { FOLLOW_UP_URGENCY } from 'services/terminologyConstants'
import { selectChartingValidationActive } from 'store/selectors/charting'
import { selectPrograms } from 'store/selectors/program'
import { selectTerminology } from 'store/selectors/terminology'
import { selectUserId } from 'store/selectors/user'
import {
  EFollowUpTemplateStateValue,
  FollowUpTemplateState,
  TFollowUpTemplateValue,
} from './FollowUpTemplate'

type FollowUpTemplateComponentProps = FollowUpTemplateState & {
  onChange: (key: string, value: TFollowUpTemplateValue) => void
  readOnly?: boolean
}

const FollowUpTemplateComponent = ({
  assignedTo,
  createdBy,
  createdOn,
  description,
  dueDate,
  onChange,
  priority,
  programs,
  readOnly,
  state,
}: FollowUpTemplateComponentProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const programsAssociated = useSelector(selectPrograms)
  const userId = useSelector(selectUserId)
  const validationActive = useSelector(selectChartingValidationActive)
  const providers = useProviders()

  const [initialized, setInitialized] = useState(false)
  const [assignedToState, setAssignedToState] = useState<PersonnelDTO | undefined>(undefined)

  const followUpUrgency = useMemo(
    () => terminology.find((term) => term.setName === FOLLOW_UP_URGENCY)?.value || [],
    [terminology]
  )

  const createdByProvider = useMemo(
    () => providers?.find((each) => each.id === createdBy),
    [createdBy, providers]
  )

  const loggedInProvider = useMemo(
    () => providers?.find((each) => each.userId === userId),
    [providers, userId]
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const handleChange = useCallback(
    (key: string, value: TFollowUpTemplateValue) => onChange(key, value),
    [onChange]
  )

  useEffect(() => {
    setAssignedToState(assignedTo)
    if (!readOnly && !isTemplateEditor) {
      const defaultPriorty = followUpUrgency.find((each) => each.name === 'Normal')
      if (loggedInProvider) {
        if (assignedTo === undefined && createdBy === undefined) {
          handleChange('assignedTo', loggedInProvider)
          setAssignedToState(loggedInProvider)
        }
        if (priority === undefined && createdBy === undefined && defaultPriorty)
          handleChange('priority', defaultPriorty)
        if (createdBy === undefined && loggedInProvider.id)
          handleChange('createdBy', loggedInProvider.id)
      }
      if (createdOn === undefined) handleChange('createdOn', dateNowIsoString())
      if (state === undefined) handleChange('state', 'Pending')
    }
    setInitialized(true)
  }, [
    assignedTo,
    createdBy,
    createdOn,
    followUpUrgency,
    handleChange,
    loggedInProvider,
    isTemplateEditor,
    priority,
    providers,
    readOnly,
    state,
    userId,
  ])

  if (!initialized) return null

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography component="h1">{t('charting.templates.follow-up.label')}</Typography>
          </Stack>
        </Grid>
        <Grid item md={6} sm={12}>
          <MISSelectDropdown
            label={t('charting.templates.follow-up.fields.state')}
            noClearIcon
            onChange={(e) => handleChange('state', e.target.value)}
            options={Object.values(EFollowUpTemplateStateValue).map((each) => ({
              label: each,
              value: each,
            }))}
            value={Object.values(EFollowUpTemplateStateValue)?.find((each) => each === state)}
          />
        </Grid>
        <Grid item sm={12}>
          <MISTextField
            error={validationActive && !description}
            helperText={
              validationActive && !description
                ? t('charting.templates.follow-up.fields.description-required')
                : undefined
            }
            inputProps={{ readOnly: readOnly }}
            label={t('charting.templates.follow-up.fields.description')}
            minRows={3}
            multiline
            onChange={(e) => handleChange('description', e.target.value)}
            required
            value={description || ''}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <MISSelectDropdown
            label={t('charting.templates.follow-up.fields.assigned-to')}
            onChange={(e) => {
              handleChange('assignedTo', e.target.value)
              setAssignedToState(e.target.value || null)
            }}
            options={
              providers?.map((provider: PersonnelDTO) => {
                return {
                  label: evaluateLabelUtil(provider?.names),
                  value: provider,
                }
              }) || []
            }
            readOnly={readOnly}
            value={providers?.find((each) => each.id === assignedToState?.id)}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <MISSelectMultiDropdown
            label={t('charting.templates.follow-up.fields.programs')}
            onChange={(e) => handleChange('programs', e.target.value || [])}
            options={programsAssociated?.map((program: ProgramTerse) => {
              return {
                label: program.name,
                value: program,
              }
            })}
            readOnly={readOnly}
            value={
              programs?.map((program) =>
                programsAssociated?.find((each) => program.id === each.id)
              ) || []
            }
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <MISSelectDropdown
            error={validationActive && !priority}
            helperText={
              validationActive && !priority
                ? t('charting.templates.follow-up.fields.priority-required')
                : undefined
            }
            label={t('charting.templates.follow-up.fields.priority')}
            onChange={(e) => handleChange('priority', e.target.value)}
            options={followUpUrgency.map((each) => ({ label: each.name as string, value: each }))}
            readOnly={readOnly}
            required
            value={priority}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <DatePicker
            disablePast
            inputFormat={IDHE_DATE_DISPLAY_FORMAT}
            label={t('charting.templates.follow-up.fields.due-date')}
            onChange={(value) => handleChange('dueDate', value || '')}
            readOnly={readOnly}
            renderInput={(props) => <MISTextField {...props} />}
            value={dueDate || null}
          />
        </Grid>
        {(createdBy || createdOn) && (
          <Grid item sm={12}>
            {createdBy && createdByProvider && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-by'
              )}${evaluateLabelUtil(createdByProvider.names)}`}</Typography>
            )}
            {createdOn && (
              <Typography>{`${t(
                'charting.templates.follow-up.fields.created-on'
              )}${isoDateToDisplayFormatWithTime(createdOn)}`}</Typography>
            )}
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default FollowUpTemplateComponent
