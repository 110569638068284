import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import EntityTemplate from 'common/components/associations/EntityTemplate'
import { programsAtom } from 'recoil/atoms'
import { ProgramControllerService } from 'services/openapi'

export default function Programs() {
  const { t } = useTranslation('common')

  const getEntitiesServiceCall = ProgramControllerService.listPrograms
  const createEntityServiceCall = ProgramControllerService.createProgram
  const updateEntityServiceCall = ProgramControllerService.updateProgram
  const updateAtomCall = useSetRecoilState(programsAtom)

  return (
    <Box sx={{ p: 2 }}>
      <h1>{t('programs.title.programs')}</h1>
      {t('programs.title.programs-description')}

      <EntityTemplate
        createEntityServiceCall={createEntityServiceCall}
        entityName={t('programs.title.programs')}
        getEntitiesServiceCall={getEntitiesServiceCall}
        isProgram
        updateAtomCall={updateAtomCall}
        updateEntityServiceCall={updateEntityServiceCall}
      />
    </Box>
  )
}
