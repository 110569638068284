import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.snow.css'
import './Charting.scss'
import 'rc-dock/dist/rc-dock.css'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContextProps, useAuth } from 'react-oidc-context'
import { UnprivilegedEditor } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import axios from 'axios'
import { DeltaStatic, Sources } from 'quill'
import DockLayout, { LayoutData, PanelData } from 'rc-dock'
import { useRecoilState, useSetRecoilState } from 'recoil'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import global from 'common/styles/global.scss'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'
import ClientRecordHeader from 'modules/client/ClientDetails/ClientRecordHeader'
import useChartingEntries from 'modules/shared/hooks/useChartingEntries'
import useClientDetails from 'modules/shared/hooks/useClientDetails'
import { FilterProps } from 'modules/shared/hooks/useRunningNotes'
import { ErrorType } from 'modules/shared/utils'
import {
  chartingEditorProgressState,
  chartingFieldsProgressState,
  isDisplayInProgressState,
} from 'recoil/charting'
import { chartingEntryIdState } from 'recoil/lastupdated'
import {
  ChartingEntryControllerService,
  ChartingEntryDTO,
  ClientControllerService,
  ClientDTO,
  EncounterServiceTemplateDTO,
  ProgramTerse,
} from 'services/openapi'
import { setActiveHistoricTabs, setValidationActive } from 'store/reducers/charting'
import { setClientDetails } from 'store/reducers/client'
import { setHistoricalTrigger } from 'store/reducers/historicalTrigger'
import { selectChartingActiveHistoricTabs } from 'store/selectors/charting'
import { selectClientDetails } from 'store/selectors/client'
import TemplateBlot from './components/blots/TemplateBlot'
import ChartingEntries from './components/ChartingEntries'
import ChartingFields from './components/ChartingFields'
import ChartingServiceFields from './components/ChartingServiceFields'
import Editor, { EMentionTypes, NavigationDetail } from './components/editor/Editor'
import HistoricalPanel from './components/historical/HistoricalPanel'
import { getMetadataForTemplate } from './components/historical/utils'

export interface TValue {
  id: number | string
  disabled?: boolean
  value: string
  type?: string
}

export type DocumentDetails = {
  fileId: string
  name: string
  fileSize: number
}

const validateChartingDataTemplates = (chartingDataDelta: DeltaStatic): boolean => {
  let isValid = true
  chartingDataDelta.forEach((eachDelta) => {
    const templateBlot = eachDelta.insert?.['template-blot']
    if (templateBlot?.templateName) {
      if (templateBlot.templateName === 'AllergyTemplate') {
        if (
          (!templateBlot.templateData?.allergenOther && !templateBlot.templateData?.allergen) ||
          (templateBlot.templateData?.allergenOther &&
            !templateBlot.templateData?.allergenSpecified)
        )
          isValid = false
        if (!templateBlot.templateData?.type) isValid = false
      } else {
        const metadata = getMetadataForTemplate(templateBlot.templateName)
        if (metadata) {
          const keys = Object.keys(metadata)
          const requiredFields = keys.filter((key) => metadata[key].required)
          requiredFields.forEach((requiredField) => {
            if (!templateBlot.templateData?.[requiredField]) {
              isValid = false
            }
          })
        }
      }
    }
  })

  return isValid
}

const Charting = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clientId } = useParams()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackError } = useSnack()
  const auth: AuthContextProps = useAuth()
  const clientDetails = useSelector(selectClientDetails)
  const activeTabInfo = useSelector(selectChartingActiveHistoricTabs)
  const [lastUpdatedChartingId, setLastUpdatedChartingId] = useRecoilState(chartingEntryIdState)
  useClientDetails(clientId)
  const [chartingEditorProgress, setChartingEditorProgress] = useRecoilState(
    chartingEditorProgressState
  )
  const [chartingFieldsProgress, setChartingFieldsProgress] = useRecoilState(
    chartingFieldsProgressState
  )
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)
  const setIsDisplayInProgress = useSetRecoilState(isDisplayInProgressState)

  const [menuItem, setMenuItem] = useState<string | undefined>(undefined)
  const [chartingData, setChartingData] = useState<ChartingEntryDTO>({
    duration: undefined,
    id: undefined,
    primaryProvider: undefined,
    serviceDateTime: undefined,
    status: undefined,
    statusComment: undefined,
    statusReason: undefined,
  })
  const [programs, setPrograms] = useState<TValue[]>([])
  const [services, setServices] = useState<TValue[]>([])

  const [applyFilters, setApplyFilters] = useState(false)
  const [filters, setFilters] = useState<FilterProps[]>([])
  const [isDisplayRevHistory, setIsDisplayRevHistory] = useState(false)

  const { chartingEntries, providers, setChartingEntries, sortData } = useChartingEntries(
    clientId || '',
    filters,
    applyFilters
  )
  const [errors, setErrors] = useState<ErrorType[]>([])

  const chartDataRef = useRef<{ delta: DeltaStatic | undefined }>()
  const chartSelectedIdRef = useRef<string | undefined>()
  const editorRef = useRef<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getAttachments: () => any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setAttachments: (attachments: any[]) => void
    getClientMentions: () => string[] | undefined
    setClientMentions: (clientMentions: string[] | undefined) => void
    getClientIdContext: () => string | undefined
    setClientIdContext: (clientId: string | undefined) => void
    getContents: () => DeltaStatic | undefined
    setContents: (contents: string | DeltaStatic) => void
    setServiceDateTime: (setServiceDateTime: string | undefined) => void
    setNavigationDetails: (navigationDetail: NavigationDetail | undefined) => void
    getNavigationDetails: () => NavigationDetail | undefined
    setPrograms: (programs: TValue[] | undefined) => void
    getPrograms: () => TValue[] | undefined
    setServices: (services: TValue[] | undefined) => void
    getServices: () => TValue[] | undefined
  }>()
  const dockLayoutRef = useRef<DockLayout | null>(null)

  useEffect(() => {
    editorRef.current?.setClientIdContext(clientId)
  }, [clientId])

  const resetCharting = useCallback(() => {
    chartSelectedIdRef.current = undefined
    editorRef.current?.setContents('')
    chartDataRef.current = { delta: undefined }
    setPrograms([])
    setServices([])
    setChartingData({
      duration: undefined,
      id: undefined,
      primaryProvider: undefined,
      serviceDateTime: undefined,
      status: undefined,
      statusComment: undefined,
      statusReason: undefined,
    })
    dispatch(setValidationActive(false))
  }, [dispatch])

  useEffect(() => {
    setMenuItem(seletedMenuItem)
    if (seletedMenuItem !== 'navigation.left-nav-menu.client.charting.in-progress') {
      editorRef.current?.setClientMentions(undefined)
    }
    const navigationDetails = editorRef.current?.getNavigationDetails()
    if (navigationDetails) {
      const updatedNavigationdetail = { ...navigationDetails, activeMenuItem: seletedMenuItem }
      editorRef.current?.setNavigationDetails(updatedNavigationdetail)
    } else {
      editorRef.current?.setNavigationDetails({ activeMenuItem: seletedMenuItem, fromMenuItem: '' })
    }
    if (
      menuItem !== seletedMenuItem &&
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress' ||
        seletedMenuItem === 'navigation.left-nav-menu.admin.admin' ||
        (seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas' &&
          navigationDetails?.fromMenuItem ===
            'navigation.left-nav-menu.client.charting.in-progress') ||
        (seletedMenuItem === 'navigation.left-nav-menu.admin.providers-staff' &&
          navigationDetails?.fromMenuItem === 'navigation.left-nav-menu.admin.admin') ||
        (seletedMenuItem === 'navigation.left-nav-menu.charting.blank-canvas' &&
          navigationDetails?.fromMenuItem === 'navigation.left-nav-menu.admin.providers-staff'))
    ) {
      //this is required because recoil needs a tick in order to save and then publish the changes onto the editorref in this case, please dont remove this
      setTimeout(() => {
        setMenuItem(seletedMenuItem)
        if (chartingEditorProgress) {
          const { chartingEntryData } = chartingEditorProgress
          const json = chartingEntryData
          editorRef.current?.setContents(json?.delta || '')
          setPrograms(json?.programs || [])
          setServices(json?.services || [])
          setChartingData({
            duration: chartingFieldsProgress?.duration,
            id: chartingFieldsProgress?.id,
            primaryProvider: chartingFieldsProgress?.primaryProvider,
            serviceDateTime: chartingFieldsProgress?.serviceDateTime,
            status: chartingFieldsProgress?.status,
            statusComment: chartingFieldsProgress?.statusComment,
            statusReason: chartingFieldsProgress?.statusReason,
          })
        } else {
          resetCharting()
        }
      }, 0)
    } else if (menuItem !== seletedMenuItem) {
      setMenuItem(seletedMenuItem)
      resetCharting()
    }
  }, [
    chartingEditorProgress,
    chartingFieldsProgress?.duration,
    chartingFieldsProgress?.id,
    chartingFieldsProgress?.primaryProvider,
    chartingFieldsProgress?.serviceDateTime,
    chartingFieldsProgress?.status,
    chartingFieldsProgress?.statusComment,
    chartingFieldsProgress?.statusReason,
    menuItem,
    resetCharting,
    seletedMenuItem,
  ])

  const setChartingDataHandler = useCallback(
    (data: ChartingEntryDTO) => {
      setChartingData(data)
      const chartingData = {
        duration: data?.duration || chartingFieldsProgress?.duration,
        id: data?.id || chartingFieldsProgress?.id,
        primaryProvider: data?.primaryProvider || chartingFieldsProgress?.primaryProvider,
        serviceDateTime: data?.serviceDateTime || chartingFieldsProgress?.serviceDateTime,
        status: data?.status || chartingFieldsProgress?.status,
        statusComment: data?.statusComment || chartingFieldsProgress?.statusComment,
        statusReason: data?.statusReason || chartingFieldsProgress?.statusReason,
      }
      setChartingFieldsProgress(chartingData)
    },
    [chartingFieldsProgress, setChartingFieldsProgress]
  )

  const handleChangeProgram = useCallback((programs: TValue[]) => {
    editorRef.current?.setPrograms(programs)
    setPrograms(programs)
  }, [])

  const handleChangeServices = useCallback((services: TValue[]) => {
    editorRef.current?.setServices(services)
    setServices(services)
  }, [])

  const storeEditorData = useCallback(() => {
    const programs = editorRef.current?.getPrograms()
    const services = editorRef.current?.getServices()
    const updatedChartingData = { ...chartDataRef.current, programs, services }
    const chartingEntryData = {
      ...JSON.parse(JSON.stringify(updatedChartingData)),
    }
    const chartEntryDTO = {
      chartingEntryData,
      status: {
        code: 'COMPLETE',
        codeSystemOid: '2.16.840.1.113883.3.1019.1.8',
      },
    }
    setChartingEditorProgress(chartEntryDTO)
  }, [setChartingEditorProgress])

  const handleChange = useCallback(
    async (_value: string, _delta: DeltaStatic, _source: Sources, editor: UnprivilegedEditor) => {
      const content = editor.getContents()

      const mentions = content
        .filter((deltaEntry) => (deltaEntry.insert ? deltaEntry.insert.mention != null : false))
        .map((deltaEntry) => deltaEntry.insert.mention)

      const navigationDetails = editorRef.current?.getNavigationDetails()
      const activeItemMenu = navigationDetails?.activeMenuItem
      chartDataRef.current = { delta: content }

      if (activeItemMenu !== 'navigation.left-nav-menu.client.charting') {
        storeEditorData()
      }
      // if client is deleted on in progress and it was navigated via blank canvas
      // then navigated to blank canvas
      if (activeItemMenu === 'navigation.left-nav-menu.client.charting.in-progress') {
        if (navigationDetails?.fromMenuItem === 'navigation.left-nav-menu.charting.blank-canvas') {
          const clients = mentions.filter((dataEntry) => dataEntry.type === EMentionTypes.Client)
          if (!clients || clients.length === 0) {
            setSeletedMenuItem('navigation.left-nav-menu.charting.blank-canvas')
            navigate(`/charting`)
            const updatedNavigationDetails = {
              ...navigationDetails,
              fromMenuItem: 'navigation.left-nav-menu.client.charting.in-progress',
            }
            editorRef.current?.setNavigationDetails(updatedNavigationDetails)
          } else {
            const clientIdInContext = editorRef.current?.getClientIdContext()
            const entryClientIdMentions = mentions
              .filter((dataEntry) => dataEntry.type === EMentionTypes.Client)
              .filter(
                // filter duplicates
                (entry, index, array) =>
                  array.findIndex((val) => val.value === entry.value) === index
              )
              .map((c) => c.id)
            const exists = entryClientIdMentions.indexOf(clientIdInContext) !== -1
            if (!exists) {
              const clientIdMentions = editorRef.current?.getClientMentions()
              const newClientId = clientIdMentions?.shift()
              if (newClientId) {
                const resp = await ClientControllerService.getClient(newClientId)
                setIsDisplayInProgress(true)
                dispatch(setClientDetails(resp))
                setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
                navigate(`/clients/client-record/${newClientId}/in-progress`)
              }
            }
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSeletedMenuItem, storeEditorData]
  )

  const handleSelectChart = useCallback(
    (chartingEntryDTO: ChartingEntryDTO, duplicate?: boolean, isChartRevHistory?: boolean) => {
      setErrors([])
      dispatch(setValidationActive(false))
      const { chartingEntryData, id } = chartingEntryDTO
      const json = chartingEntryData
      if (duplicate) chartSelectedIdRef.current = undefined
      else chartSelectedIdRef.current = id
      editorRef.current?.setContents(json?.delta || '')
      setPrograms(json?.programs || [])
      setServices(json?.services || [])
      setChartingData(chartingEntryDTO)
      if (isChartRevHistory) {
        setIsDisplayRevHistory(true)
      } else {
        setIsDisplayRevHistory(false)
      }
      if (json?.documents) {
        editorRef.current?.setAttachments(json?.documents)
      }
    },
    [dispatch]
  )

  const handleSelectChartRevHistory = useCallback(
    (data: ChartingEntryDTO, isLatestVersion: boolean) => {
      let chartingDTO = data
      if (isLatestVersion && chartingEntries) {
        chartingDTO = chartingEntries.find((dto) => dto.id === data.id) || data
      }
      handleSelectChart(chartingDTO, false, !isLatestVersion)
      setChartingDataHandler(chartingDTO)
      setIsDisplayRevHistory(!isLatestVersion)
    },
    [chartingEntries, handleSelectChart, setChartingDataHandler, setIsDisplayRevHistory]
  )

  // need to make is async by settimeout otherwise it executes before recoil is updated,
  // works with async wait also. Also tried giving window.location.pathname
  //as dependency, but it doesn't work with it.
  useEffect(() => {
    setTimeout(() => {
      if (seletedMenuItem === 'navigation.left-nav-menu.client.charting') {
        if (chartingEntries && chartingEntries?.length > 0) {
          const selectedId = lastUpdatedChartingId || chartingEntries[0]?.id
          const selectedChartingEntry = chartingEntries?.find(
            (chartingEntry) => chartingEntry.id === selectedId
          )
          handleSelectChart(selectedChartingEntry || chartingEntries[0])
        } else {
          resetCharting()
        }
      }
    }, 500)
  }, [chartingEntries, handleSelectChart, lastUpdatedChartingId, resetCharting, seletedMenuItem])

  const handleSelectMention = useCallback(
    async (item: ClientDTO | ProgramTerse | EncounterServiceTemplateDTO, type: EMentionTypes) => {
      switch (type) {
        case EMentionTypes.Client:
          try {
            const resp = await ClientControllerService.getClient(item.id || '')
            const navigationDetails = editorRef.current?.getNavigationDetails()
            const activeItemMenu = navigationDetails?.activeMenuItem
            const fromMenuItem = navigationDetails?.fromMenuItem
            if (activeItemMenu !== 'navigation.left-nav-menu.client.charting') {
              storeEditorData()
            }
            if (
              activeItemMenu === 'navigation.left-nav-menu.charting.blank-canvas' ||
              activeItemMenu === 'navigation.left-nav-menu.admin.providers-staff'
            ) {
              setIsDisplayInProgress(true)
              dispatch(setClientDetails(resp))
              setSeletedMenuItem('navigation.left-nav-menu.client.charting.in-progress')
              navigate(`/clients/client-record/${item.id}/in-progress`)
              const updatedNavigationDetails = navigationDetails
                ? {
                    ...navigationDetails,
                    fromMenuItem: 'navigation.left-nav-menu.charting.blank-canvas',
                  }
                : {
                    activeMenuItem: '',
                    fromMenuItem: 'navigation.left-nav-menu.charting.blank-canvas',
                  }
              editorRef.current?.setNavigationDetails(updatedNavigationDetails)
            }
            if (
              activeItemMenu === 'navigation.left-nav-menu.client.charting.in-progress' &&
              fromMenuItem === 'navigation.left-nav-menu.charting.blank-canvas'
            ) {
              const clientIdMentionList = editorRef.current?.getClientMentions() || []
              if (item.id) {
                const exists = clientIdMentionList.indexOf(item.id) !== -1
                if (!exists) {
                  const updatedClientIdList = [...clientIdMentionList, item.id]
                  editorRef.current?.setClientMentions(updatedClientIdList)
                }
              }
            }
          } catch (error) {
            handleApiError(error)
          }
          break
        default:
          break
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleApiError, setSeletedMenuItem, storeEditorData]
  )

  const isDisplaySaveButton = useCallback(() => {
    if (seletedMenuItem === 'navigation.left-nav-menu.client.charting' && isDisplayRevHistory) {
      return false
    }
    return (
      (seletedMenuItem === 'navigation.left-nav-menu.client.charting' &&
        chartSelectedIdRef.current) ||
      seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress'
    )
  }, [isDisplayRevHistory, seletedMenuItem])

  const isDisplayCancelButton = useCallback(() => {
    return seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress'
  }, [seletedMenuItem])

  const handleCancel = useCallback(() => {
    setIsDisplayInProgress(false)
    setChartingEditorProgress(undefined)
    setChartingFieldsProgress(undefined)
    editorRef.current?.setClientMentions(undefined)
    setSeletedMenuItem('navigation.left-nav-menu.client.charting')
    navigate(`/clients/client-record/${clientId}/charting`)
  }, [
    clientId,
    navigate,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
  ])

  const handleSubmit = useCallback(async () => {
    const validate = () => {
      let isValid = true
      const errors: ErrorType[] = []
      if (!chartingData?.serviceDateTime) {
        isValid = false
        errors.push({
          field: `charting-service-date-time`,
          message: t('charting.validation.charting-service-date-time-required'),
        })
      }
      if (!chartingData?.primaryProvider) {
        isValid = false
        errors.push({
          field: `charting-primary-provider`,
          message: t('charting.validation.charting-primary-provider-required'),
        })
      }
      if (
        chartDataRef.current?.delta &&
        (chartingData.status?.code === 'COMPLETE' || chartingData.status?.code === 'REVISED')
      )
        isValid = validateChartingDataTemplates(chartDataRef.current.delta)
      return { errors, isValid }
    }

    const saveChartingEntries = async () => {
      if (clientId) {
        const documents: DocumentDetails[] = []
        if (chartDataRef.current && chartDataRef.current.delta) {
          const blotIds: string[] = []
          const deltaEntries = Object.values(chartDataRef.current.delta)
          for (const eachDelta of deltaEntries[0]) {
            const templateBlot = eachDelta.insert?.['template-blot']

            if (templateBlot?.blotId) {
              blotIds.push(templateBlot.blotId)
            }
          }
          try {
            await TemplateBlot.saveAllTemplates(blotIds)
          } catch (err) {
            showSnackError('Erro saving one or more templates')
            return
          }
          const attachments = editorRef.current?.getAttachments()

          if (attachments && attachments.length > 0) {
            try {
              const newAttachments = attachments.filter((attachment) => !attachment.fileId)
              const oldAttachments = attachments.filter((attachment) => attachment.fileId)
              if (newAttachments.length !== 0) {
                const documentDTOs = newAttachments.map((attachment) => ({
                  associatedEntities: [{ entityId: clientId, entityType: 'CLIENT_CHARTING_ENTRY' }],
                  ontologyTitle: { code: '74155-3', codeSystemOid: '2.16.840.1.113883.6.1' },
                }))
                const formData = new FormData()

                newAttachments.forEach((attachment) => {
                  formData.append('files', attachment.file)
                })

                formData.append('requestBodyJson', JSON.stringify(documentDTOs))
                const token = auth.user?.access_token || ''
                const result = await axios.post(
                  `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents`,
                  formData,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  }
                )

                result.data.forEach((document) => {
                  documents.push({
                    fileId: document.id,
                    fileSize: document.fileSize,
                    name: document.fileName,
                  })
                })
              }
              documents.push(...oldAttachments)
            } catch (err) {
              handleApiError(err)
              return
            }
          }

          for (const eachDelta of deltaEntries[0]) {
            const templateBlot = eachDelta.insert?.['template-blot']

            if (templateBlot?.blotId && templateBlot.templateData) {
              templateBlot.templateData = TemplateBlot.refs[templateBlot.blotId].current?.getData()
            }
          }

          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        if (seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress') {
          let response
          try {
            response = await ChartingEntryControllerService.createChartingEntry(clientId, {
              chartingEntryData: { ...chartDataRef.current, documents, programs, services },
              duration: chartingData?.duration,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: chartingData?.status,
            })
          } catch (err) {
            handleApiError(err)
          }
          setLastUpdatedChartingId(response.id as string)
          await setChartingEntries(sortData([response, ...(chartingEntries || [])]))
          setChartingEditorProgress(undefined)
          setChartingFieldsProgress(undefined)
          editorRef.current?.setClientMentions(undefined)
          setIsDisplayInProgress(false)
          dispatch(setHistoricalTrigger())
          setSeletedMenuItem('navigation.left-nav-menu.client.charting')
          navigate(`/clients/client-record/${clientId}/charting`)
          const navigationDetails = editorRef.current?.getNavigationDetails()
          if (navigationDetails) {
            const updatedNavigationdetail = { ...navigationDetails, fromMenuItem: '' }
            editorRef.current?.setNavigationDetails(updatedNavigationdetail)
          }
        } else if (chartSelectedIdRef.current) {
          let response
          try {
            response = await ChartingEntryControllerService.updateChartingEntry(
              clientId,
              chartSelectedIdRef.current,
              {
                chartingEntryData: {
                  ...chartDataRef.current,
                  documents,
                  programs,
                  services,
                },
                duration: chartingData?.duration,
                primaryProvider: chartingData?.primaryProvider,
                serviceDateTime: chartingData?.serviceDateTime,
                status: chartingData?.status,
                statusComment: chartingData.statusComment,
                statusReason: chartingData.statusReason,
              }
            )
          } catch (err) {
            handleApiError(err)
          }
          setLastUpdatedChartingId(response.id as string)
          await setChartingEntries(
            sortData(
              chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) || []
            )
          )
          dispatch(setHistoricalTrigger())
        }
      }
    }

    const { errors, isValid } = validate()
    if (isValid) {
      saveChartingEntries()
      setErrors([])
      dispatch(setValidationActive(false))
    } else {
      setErrors(errors)
      dispatch(setValidationActive(true))
      showSnackError(t('charting.templates.mandatory-fields-missing'))
    }
  }, [
    chartingData?.serviceDateTime,
    chartingData?.primaryProvider,
    chartingData.status,
    chartingData?.duration,
    chartingData.statusComment,
    chartingData.statusReason,
    t,
    clientId,
    seletedMenuItem,
    showSnackError,
    auth.user?.access_token,
    handleApiError,
    setLastUpdatedChartingId,
    setChartingEntries,
    sortData,
    chartingEntries,
    setChartingEditorProgress,
    setChartingFieldsProgress,
    setIsDisplayInProgress,
    setSeletedMenuItem,
    navigate,
    programs,
    services,
    dispatch,
  ])

  const handleStateChange = useCallback(
    async (data: ChartingEntryDTO) => {
      const validate = () => {
        let isValid = true
        const errors: ErrorType[] = []
        if (!chartingData?.serviceDateTime) {
          isValid = false
          errors.push({
            field: `charting-service-date-time`,
            message: t('charting.validation.charting-service-date-time-required'),
          })
        }
        if (!chartingData?.primaryProvider) {
          isValid = false
          errors.push({
            field: `charting-primary-provider`,
            message: t('charting.validation.charting-primary-provider-required'),
          })
        }
        if (
          chartDataRef.current?.delta &&
          (chartingData.status?.code === 'COMPLETE' || chartingData.status?.code === 'REVISED')
        )
          isValid = validateChartingDataTemplates(chartDataRef.current.delta)
        return { errors, isValid }
      }
      const { errors, isValid } = validate()
      if (isValid) {
        setChartingData(data)
        setErrors([])
        dispatch(setValidationActive(false))
      } else {
        setErrors(errors)
        dispatch(setValidationActive(true))
        showSnackError(t('charting.templates.mandatory-fields-missing'))
        return
      }
      if (clientId && chartSelectedIdRef.current) {
        // filter out form builder template layout from charting data
        if (chartDataRef.current && chartDataRef.current.delta) {
          const filteredDelta = chartDataRef.current.delta.ops?.map((each) => {
            if (
              each.insert &&
              each.insert['template-blot'] &&
              each.insert['template-blot'].isFormBuilder
            ) {
              const templateData = {
                components: each.insert['template-blot'].templateData?.components,
              }
              const templateBlot = { ...each.insert['template-blot'], templateData }
              return { insert: { 'template-blot': templateBlot } }
            }
            return each
          })
          chartDataRef.current.delta.ops = filteredDelta
        }
        let response
        try {
          response = await ChartingEntryControllerService.updateChartingEntry(
            clientId,
            chartSelectedIdRef.current,
            {
              chartingEntryData: {
                ...chartDataRef.current,
                programs,
                services,
              },
              duration: chartingData?.duration,
              primaryProvider: chartingData?.primaryProvider,
              serviceDateTime: chartingData?.serviceDateTime,
              status: data?.status,
              statusComment: data.statusComment,
              statusReason: data.statusReason,
            }
          )
        } catch (err) {
          handleApiError(err)
        }
        setLastUpdatedChartingId(response.id as string)
        await setChartingEntries(
          sortData(
            chartingEntries?.map((entry) => (entry?.id === response?.id ? response : entry)) || []
          )
        )
      }
    },
    [
      clientId,
      chartingData?.serviceDateTime,
      chartingData?.primaryProvider,
      chartingData?.status?.code,
      chartingData?.duration,
      t,
      dispatch,
      showSnackError,
      setLastUpdatedChartingId,
      setChartingEntries,
      sortData,
      chartingEntries,
      programs,
      services,
      handleApiError,
    ]
  )

  const editor = useMemo(
    () => (
      <Editor onChange={handleChange} onMentionResultSelect={handleSelectMention} ref={editorRef} />
    ),
    [handleChange, handleSelectMention]
  )

  const DockPanelTabs = {
    ChartingDocument: {
      cached: true,
      content: (
        <div className="charting-view" style={{ overflow: 'auto' }}>
          <div className="charting-content-view">
            <Box
              sx={{
                backgroundColor: global.WHITE,
                borderRadius: '8px',
                pl: 3,
                pr: 3,
                pt: 3,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {isDisplaySaveButton() && (
                  <Box display="flex">
                    <MISButton color="primary" onClick={handleSubmit} variant="contained">
                      {t('common.button.save')}
                    </MISButton>
                    {isDisplayCancelButton() && (
                      <MISButton
                        color="secondary"
                        onClick={handleCancel}
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                      >
                        {t('common.button.cancel')}
                      </MISButton>
                    )}
                  </Box>
                )}
              </div>

              <ChartingFields
                chartingData={chartingData}
                errors={errors}
                onChangeChartingData={setChartingDataHandler}
                onChangeState={handleStateChange}
                onSelectChartRevHistory={handleSelectChartRevHistory}
                providers={providers || []}
              />
              <ChartingServiceFields
                onChangePrograms={handleChangeProgram}
                onChangeServices={handleChangeServices}
                programs={programs}
                services={services}
              />
            </Box>
            <Box sx={{ pb: 3, pl: 3, pr: 3 }}>{editor}</Box>
          </div>
        </div>
      ),
      id: 'ChartingDocument',
      title: 'Charting Document',
    },
    ChartingEntries: {
      cached: true,
      content: (
        <div className="charting-view">
          {seletedMenuItem === 'navigation.left-nav-menu.client.charting' && clientDetails && (
            <div className="charting-list-view">
              <ChartingEntries
                applyFilters={applyFilters}
                chartingEntries={chartingEntries || []}
                filters={filters}
                onSelect={handleSelectChart}
                providers={providers || []}
                setApplyFilters={setApplyFilters}
                setFilters={setFilters}
              />
            </div>
          )}
        </div>
      ),
      id: 'ChartingEntries',
      title: 'Charting Entries',
    },
  }

  useEffect(
    () => editorRef.current?.setServiceDateTime(chartingData.serviceDateTime),
    [chartingData.serviceDateTime]
  )

  const historicalPanel = HistoricalPanel({ dockLayoutRef })

  const dockLayout: LayoutData = {
    dockbox: {
      children: [
        historicalPanel.panel,
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 50, widthFlex: 1 },
              tabs: [DockPanelTabs.ChartingEntries],
            },
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  const dockLayoutClientNoContext: LayoutData = {
    dockbox: {
      children: [
        historicalPanel.panel,
        {
          children: [
            {
              group: 'card custom',
              panelLock: { minWidth: 400, widthFlex: 7 },
              tabs: [DockPanelTabs.ChartingDocument],
            },
          ],
          mode: 'horizontal',
          size: 400,
        },
      ],
      id: 'main',
      mode: 'vertical',
    },
  }

  const isTabUpdateInProgress = useRef(false)

  const onLayoutChange = useCallback(
    (layoutData) => {
      if (isTabUpdateInProgress.current) {
        return
      }

      if (layoutData.dockbox.children[0].tabs.length < activeTabInfo.length) {
        const tabs = layoutData.dockbox.children[0].tabs.map((tab) => tab.id)
        const newTabs = activeTabInfo.filter((tab) => tabs.includes(tab.templateId))
        dispatch(setActiveHistoricTabs(newTabs))
        return
      }
    },
    [activeTabInfo, dispatch]
  )

  useEffect(() => {
    // When the user changes, all components need to be updated to use the newest information.
    if (dockLayoutRef !== undefined && dockLayoutRef.current !== undefined) {
      dockLayoutRef.current?.updateTab('ChartingEntries', DockPanelTabs.ChartingEntries)
      dockLayoutRef.current?.updateTab('ChartingDocument', DockPanelTabs.ChartingDocument)
    }
  }, [DockPanelTabs.ChartingDocument, DockPanelTabs.ChartingEntries])

  const location = useLocation()

  useEffect(() => {
    const historicalPanel1 = dockLayoutRef.current?.find('historicalPanel') as PanelData
    const isBlankCanvas =
      location.pathname.endsWith('/charting') && !location.pathname.includes('client-record')

    if (isBlankCanvas) {
      return
    } else if (activeTabInfo && historicalPanel1?.tabs.length === 0) {
      isTabUpdateInProgress.current = true
      const promises: Promise<void>[] = []
      activeTabInfo.forEach((tab) => {
        const promise = historicalPanel.addHistorical(tab.templateName, tab.filters || [])
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        isTabUpdateInProgress.current = false
      })
    }
  }, [activeTabInfo, dispatch, historicalPanel, location])

  return (
    <MISBaseContainer>
      {seletedMenuItem !== 'navigation.left-nav-menu.charting.blank-canvas' && (
        <ClientRecordHeader />
      )}
      {clientId && seletedMenuItem !== 'navigation.left-nav-menu.client.charting.in-progress' && (
        <DockLayout
          defaultLayout={dockLayout}
          groups={historicalPanel.groups}
          onLayoutChange={onLayoutChange}
          ref={dockLayoutRef}
          style={{ minHeight: '100vh' }}
        />
      )}
      {(!clientId ||
        seletedMenuItem === 'navigation.left-nav-menu.client.charting.in-progress') && (
        <DockLayout
          defaultLayout={dockLayoutClientNoContext}
          groups={historicalPanel.groups}
          onLayoutChange={onLayoutChange}
          ref={dockLayoutRef}
          style={{ minHeight: '100vh' }}
        />
      )}
    </MISBaseContainer>
  )
}

export default Charting
