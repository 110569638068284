import { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContextProps } from 'react-oidc-context'
import { Link } from 'react-router-dom'
import { AppBar, Badge, Box, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import { User } from 'oidc-client-ts'
import MISButton from 'common/components/MISButton'
import NotificationsContainer from 'common/components/NotificationContainer'
import PinnedMenuItem from 'common/components/topnavigation/menu-items/PinnedMenuItem'
import TOP_NAVIGATION_MENU_CONFIG from 'common/components/topnavigation/TopNavigationMenu'
import global from 'common/styles/global.scss'
import { NotificationContext } from 'core/routing/NotificationProvider'
import './TopNavigation.scss'
import { MODALS } from 'modules/shared/constants'
import WarningDialog from 'modules/shared/Dialogs/WarningDialog'

const TOP_NAVIGATION_MENU_ID = 'top-navigation-menu-id'

type TopNavigationProps = {
  auth: AuthContextProps
  user: User | null | undefined
}

const TopNavigation = (user: TopNavigationProps) => {
  const { notifications } = useContext(NotificationContext)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null)
  const [logoutConfirmationDialog, setLogoutConfirmationDialog] = useState(false)

  const preferredUsername = useMemo(
    () => user?.user?.profile?.preferred_username,
    [user?.user?.profile?.preferred_username]
  )

  const getMessage = useCallback((key: string): string => {
    const count = TOP_NAVIGATION_MENU_CONFIG.filter((item) => item.key === key)[0].children.length
    const message = 'show (' + count + ') new ' + key

    return message
  }, [])

  const handleClickMenuItem = useCallback(
    (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, key: string) => {
      if (key === 'logout') {
        event.stopPropagation()
        setLogoutConfirmationDialog(true)
      }
    },
    []
  )

  const handleLogoutConfirmation = useCallback(() => {
    const idToken = user.auth.user?.id_token
    const rootUrl = user.auth.settings.authority
    const silentHref = `${rootUrl}/protocol/openid-connect/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(
      window.location.origin + `/silent-logout`
    )}`
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = silentHref
    document.body.appendChild(iframe)

    // Set a timeout to redirect to the login page after the logout URL has been hit
    setTimeout(() => {
      document.body.removeChild(iframe)
      user.auth.removeUser()
      user.auth.revokeTokens()
      localStorage.clear()

      window.location.href = `${window.location.origin}/login`
    }, 100)
  }, [user.auth])

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement | HTMLLIElement>, key: string) => {
      event.stopPropagation()
      setSelectedMenuItem(key)
      setAnchorEl(event.currentTarget)
    },
    []
  )

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
    setSelectedMenuItem(null)
  }, [])

  const renderMenuItems = useCallback(
    (key: string) => {
      // return component if defined
      if (key === 'pinned-clients') return <PinnedMenuItem onClose={handleMenuClose} />

      // return children array menu items
      const customMenuItems: JSX.Element[] = []
      TOP_NAVIGATION_MENU_CONFIG.filter((item) => item.key === key).map((item) => {
        return item.children.map((child, index) => {
          return customMenuItems.push(
            <MenuItem
              className="text"
              component={child.key !== 'logout' ? Link : 'div'}
              divider={index === item.children.length - 1 && key === 'notifications' ? true : false}
              key={child.key}
              onClick={(evt) => handleClickMenuItem(evt, child.key)}
              {...(child.key !== 'logout' ? { to: child.key } : {})}
            >
              <span>{child.icon}</span>
              <span className="label">{child.label}</span>
            </MenuItem>
          )
        })
      })

      if (key === 'notifications') {
        customMenuItems.push(
          <MISButton className="notifications">View All Notifications</MISButton>
        )
      }

      return customMenuItems
    },
    [handleClickMenuItem, handleMenuClose]
  )

  const renderMenu = useCallback(
    (key: string) => (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        id={TOP_NAVIGATION_MENU_ID}
        keepMounted
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {key === 'account' && (
          <MenuItem className="text" divider key={key}>
            {preferredUsername}
          </MenuItem>
        )}
        {key === 'notifications' ? (
          <NotificationsContainer messages={notifications} />
        ) : (
          renderMenuItems(key)
        )}
      </Menu>
    ),
    [anchorEl, handleMenuClose, notifications, preferredUsername, renderMenuItems]
  )

  useEffect(() => {
    const notification = TOP_NAVIGATION_MENU_CONFIG.filter((item) => item.key === 'notifications')
    const notificationChildren = notification[0] ? notification[0].children : []
    if (notificationChildren.length > 0) {
      notificationChildren[0].label = preferredUsername + ' logged in'
    }
  }, [preferredUsername])

  return (
    <>
      <Box className="top-nav">
        <AppBar className="bar" position="static">
          <Toolbar sx={{ display: 'inline', float: 'right', margin: `${global.MARGIN_SM}` }}>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex' }}>
              {TOP_NAVIGATION_MENU_CONFIG.map((item, index) => {
                return (
                  <IconButton
                    aria-haspopup="true"
                    aria-label={getMessage(item.key)}
                    className="icon"
                    color="inherit"
                    key={'top-nav-icon-button' + index}
                    onClick={(evt) => handleMenuOpen(evt, item.key)}
                    size="large"
                  >
                    <Badge badgeContent={item.badgeCount} color="error">
                      {item.icon}
                    </Badge>
                  </IconButton>
                )
              })}
            </Box>
          </Toolbar>
        </AppBar>
        {selectedMenuItem && renderMenu(selectedMenuItem)}
      </Box>
      <WarningDialog
        entity="Logout"
        onCancel={() => setLogoutConfirmationDialog(false)}
        onSave={handleLogoutConfirmation}
        open={logoutConfirmationDialog}
        type={MODALS.LOGOUT_WARNING}
      />
    </>
  )
}

export default TopNavigation
