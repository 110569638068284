import {
  ClientDTO,
  ClientIdentifier,
  ClientName,
  CodedConceptDto,
  PersonnelDTO,
  PersonnelNameDTO,
} from 'services/openapi'

export const getClientAgeString = (birthdate: string, enddate?: string): string => {
  const toDate = enddate ? new Date(enddate) : new Date()
  const dob = new Date(birthdate)

  let ageYears = toDate.getFullYear() - dob.getFullYear()
  let ageMonths = toDate.getMonth() - dob.getMonth()

  if (ageMonths < 0) {
    ageYears--
    ageMonths += 12
  }

  return `${ageYears}y ${ageMonths}m`
}

export const getClientAgeInDaysString = (birthdate: string, dischargedate: string): string => {
  const dischargeDate = new Date(dischargedate)
  const dob = new Date(birthdate)

  const timeDiff = dischargeDate.getTime() - dob.getTime()
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24))

  return `${daysDiff}`
}

export const getIdentifierValue = (
  identifierKey: string,
  client: ClientDTO,
  identifierTypeOptions: CodedConceptDto[]
) => {
  const identifier = identifierTypeOptions?.find((option) => option.name === identifierKey) || null

  return (
    identifier &&
    client.clientIdentifiers?.find(
      (obj: ClientIdentifier) =>
        obj.type?.code === identifier.code && obj.type?.codeSystemOid === identifier.codeSystemOid
    )?.value
  )
}

export const getClientPreferredName = (client: ClientDTO | undefined) => {
  if (!client?.names) return ''
  const preferredName =
    client?.names.find((name: ClientName) => name.preferredName === true) || client?.names[0]
  const { firstName, lastName, middleName } = preferredName
  const fullNameWithoutSuffix = [firstName, middleName, lastName].filter(Boolean).join(' ').trim()
  return fullNameWithoutSuffix
}

export const getClientFullName = (client: ClientDTO | undefined) => {
  if (!client?.names) return ''
  const preferredName =
    client?.names.find((name: ClientName) => name.preferredName === true) || client?.names[0]
  const { firstName, lastName, middleName, prefix, suffix } = preferredName
  const fullNameWithoutSuffix = [prefix, firstName, middleName, lastName]
    .filter(Boolean)
    .join(' ')
    .trim()
  return suffix ? `${fullNameWithoutSuffix}, ${suffix}` : fullNameWithoutSuffix
}

export const getClientLegalName = (client: ClientDTO | undefined) => {
  if (!client?.names) return ''
  const preferredName =
    client?.names.find((name: ClientName) => name.type?.code === 'L') || client?.names[0]
  const { firstName, lastName, middleName, prefix, suffix } = preferredName
  const fullNameWithoutSuffix = [prefix, firstName, middleName, lastName]
    .filter(Boolean)
    .join(' ')
    .trim()
  return suffix ? `${fullNameWithoutSuffix}, ${suffix}` : fullNameWithoutSuffix
}

export const getPersonnelFullName = (personnel: PersonnelDTO | null) => {
  if (!personnel?.names) return ''
  const preferredName =
    personnel?.names.find((name: PersonnelNameDTO) => name.preferredName === true) ||
    personnel?.names[0]
  const { firstName, lastName, middleName, prefix, suffix } = preferredName
  const fullNameWithoutSuffix = [prefix, firstName, middleName, lastName]
    .filter(Boolean)
    .join(' ')
    .trim()
  return suffix ? `${fullNameWithoutSuffix}, ${suffix}` : fullNameWithoutSuffix
}
