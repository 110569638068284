import 'quill-mention/dist/quill.mention.css'
import 'react-quill/dist/quill.snow.css'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UnprivilegedEditor } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { DeltaStatic, Sources } from 'quill'
import MISChip from 'common/components/form/MISChip'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import MISTextField from 'common/components/form/MISTextField'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { FormSchemaControllerService, FormSchemaDTO } from 'services/openapi'
import { setCurrentTemplate, setCurrentTemplateType, setMyTemplates } from 'store/reducers/charting'
import { selectChartingCurrentTemplate, selectChartingMyTemplates } from 'store/selectors/charting'
import { selectDataDomains } from 'store/selectors/permission'
import Editor from '../editor/Editor'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'

export enum TEMPLATE_TYPE {
  AGGREGATE_TEMPLATE = 'AGGREGATE_TEMPLATE',
  USER_DEFINED_BASE_TEMPLATE = 'USER_DEFINED_BASE_TEMPLATE',
}

export interface TValue {
  id: number | string
  disabled?: boolean
  value: string
  type?: string
}

const TemplateEditor = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currTemplate = useSelector(selectChartingCurrentTemplate)
  const dataDomains = useSelector(selectDataDomains)
  const myTemplates = useSelector(selectChartingMyTemplates)
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const { showSnackSuccess } = useSnack()

  const [initialized, setInitialized] = useState(false)

  const currTemplateRef = useRef<FormSchemaDTO | undefined>()
  const editorRef = useRef<{
    getContents: () => DeltaStatic | undefined
    setContents: (contents: string | DeltaStatic) => void
  }>()
  const myTemplatesRef = useRef<FormSchemaDTO[] | undefined>(myTemplates)

  const handleChange = useCallback(
    (_value: string, _delta: DeltaStatic, _source: Sources, editor: UnprivilegedEditor) => {
      const content = editor.getContents()
      let hasTemplate = false
      let hasFormBuilder = false
      if (content.ops)
        for (const op of content.ops) {
          if (op.insert['template-blot']) {
            if (!hasTemplate) hasTemplate = true
            if (
              !hasFormBuilder &&
              op.insert['template-blot'].templateName === TEMPLATES.FormBuilderTemplate.name
            )
              hasFormBuilder = true
          }
        }
      if (!hasTemplate) dispatch(setCurrentTemplateType(undefined))
      else
        dispatch(
          setCurrentTemplateType(
            hasFormBuilder
              ? TEMPLATE_TYPE.USER_DEFINED_BASE_TEMPLATE
              : TEMPLATE_TYPE.AGGREGATE_TEMPLATE
          )
        )
    },
    [dispatch]
  )

  const handlePublishTemplate = useCallback(async () => {
    try {
      const content = editorRef.current?.getContents()
      if (content) {
        const filteredDelta = content?.ops?.map((each) => {
          if (
            each.insert &&
            each.insert['template-blot'] &&
            each.insert['template-blot'].isFormBuilder
          ) {
            const templateBlot = {
              ...each.insert['template-blot'],
            }
            return { insert: { 'template-blot': templateBlot } }
          }
          return each
        })
        content.ops = filteredDelta
      }
      const resp: FormSchemaDTO = await FormSchemaControllerService.putFormSchema({
        ...currTemplate,
        designation: currTemplate?.designation || 'PRIVATE',
        schema: editorRef.current ? JSON.stringify(content) : undefined,
        state: 'PUBLISHED',
      })
      dispatch(setCurrentTemplate(resp))
      dispatch(
        setMyTemplates([
          resp,
          ...(myTemplatesRef.current?.filter((each) => each.id !== resp.id) || []),
        ])
      )
      showSnackSuccess(t('api.save-success'))
      navigate('/admin/template-management')
    } catch (error) {
      handleApiError(error)
    }
  }, [currTemplate, dispatch, handleApiError, navigate, showSnackSuccess, t])

  const handleSaveTemplate = useCallback(() => {
    const saveTemplate = async (template: FormSchemaDTO, editor) => {
      try {
        const content = editor.getContents()
        const filteredDelta = content.ops?.map((each) => {
          if (
            each.insert &&
            each.insert['template-blot'] &&
            each.insert['template-blot'].isFormBuilder
          ) {
            const templateBlot = {
              ...each.insert['template-blot'],
            }
            return { insert: { 'template-blot': templateBlot } }
          }
          return each
        })
        content.ops = filteredDelta
        const resp: FormSchemaDTO = await FormSchemaControllerService.putFormSchema({
          ...template,
          designation: template.designation || 'PRIVATE',
          schema: JSON.stringify(content),
          state: 'DRAFT',
        })
        dispatch(setCurrentTemplate(resp))
        dispatch(
          setMyTemplates([
            resp,
            ...(myTemplatesRef.current?.filter((each) => each.id !== resp.id) || []),
          ])
        )
        showSnackSuccess(t('api.save-success'))
      } catch (error) {
        handleApiError(error)
      }
    }
    saveTemplate(currTemplateRef.current || {}, editorRef.current)
  }, [dispatch, handleApiError, showSnackSuccess, t])

  const handleCancel = useCallback(() => {
    dispatch(setCurrentTemplate(undefined))
    navigate('/admin/template-management')
  }, [dispatch, navigate])

  const editor = useMemo(
    () => <Editor disableMentions onChange={handleChange} ref={editorRef} />,
    [handleChange]
  )

  useEffect(() => {
    currTemplateRef.current = currTemplate
    if (!initialized && editorRef.current) {
      setInitialized(true)
      editorRef.current.setContents(currTemplate?.schema ? JSON.parse(currTemplate.schema) : '')
    }
  }, [currTemplate, initialized])

  useEffect(() => {
    myTemplatesRef.current = myTemplates
  }, [myTemplates])

  return (
    <Box sx={{ background: '#fff', mt: 6 }}>
      <SectionHeader
        onSaves={[handleCancel, handleSaveTemplate, handlePublishTemplate]}
        saveLabel={[
          { color: 'secondary', label: t('charting.canvas.cancel') },
          { color: 'primary', label: t('charting.canvas.save') },
          { color: 'primary', label: t('charting.canvas.save-and-publish') },
        ]}
        title={t('charting.template-editor.label')}
      />
      <Grid container spacing={2} sx={{ display: 'flex', p: 3 }}>
        <Grid item xs={4}>
          <MISTextField
            disabled={!!currTemplate?.id}
            id="template-name"
            label={t('charting.template-editor.template-name')}
            onChange={(e) =>
              dispatch(setCurrentTemplate({ ...currTemplate, name: e.target.value }))
            }
            required
            value={currTemplate?.name || ''}
          />
        </Grid>
        <Grid item xs={4}>
          {dataDomains && (
            <MISSelectDropdown
              disabled={!!currTemplate?.id}
              id="data-domain"
              label={t('charting.template-editor.data-domain')}
              onChange={(e) =>
                dispatch(setCurrentTemplate({ ...currTemplate, dataDomainId: e.target.value }))
              }
              options={dataDomains.map((domain) => ({
                label: domain.name as string,
                value: domain.id,
              }))}
              required
              value={currTemplate?.dataDomainId}
            />
          )}
        </Grid>
        <Grid alignContent="center" item xs={2}>
          <ToggleButtonGroup
            aria-label="template designation"
            color="primary"
            exclusive
            onChange={(_, value) =>
              dispatch(setCurrentTemplate({ ...currTemplate, designation: value }))
            }
            size="small"
            value={currTemplate?.designation || t('charting.template-editor.private')}
          >
            <ToggleButton aria-label="private" value={t('charting.template-editor.private')}>
              {t('charting.template-editor.private')}
            </ToggleButton>
            <ToggleButton aria-label="public" value={t('charting.template-editor.public')}>
              {t('charting.template-editor.public')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid alignContent="center" item xs={2}>
          <MISChip
            color={
              currTemplate?.state === t('charting.template-editor.published')
                ? 'primary'
                : 'default'
            }
            label={currTemplate?.state || t('charting.template-editor.draft')}
          />
        </Grid>
        <Grid item xs={12}>
          <MISTextField
            id="description"
            label={t('charting.template-editor.description')}
            multiline
            onChange={(e) =>
              dispatch(setCurrentTemplate({ ...currTemplate, description: e.target.value }))
            }
            value={currTemplate?.description || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <MISTextField
            id="tags"
            label={t('charting.template-editor.tags')}
            onChange={(e) =>
              dispatch(setCurrentTemplate({ ...currTemplate, tags: e.target.value }))
            }
            required
            value={currTemplate?.tags || ''}
          />
        </Grid>
      </Grid>
      <Box sx={{ pb: 3, pl: 3, pr: 3 }}>{editor}</Box>
    </Box>
  )
}

export default TemplateEditor
