import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import {
  IDHE_DATE_DISPLAY_FORMAT,
  isoDateToDisplayFormatInUTC,
  LOCAL_DATE_FORMAT,
} from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { programGroupAtom } from 'recoil/atoms'
import AddEditDialog from '../AddEditDialog'

function createRow(name, description, startDate, endDate, id, isTerminated, businessLineId) {
  const row = {
    businessLineId,
    description,
    endDate,
    id,
    isTerminated,
    name,
    startDate,
  }
  return row
}

const columns = [
  {
    disablePadding: true,
    id: 'name',
    isSortable: true,
    label: 'types.table-header.name',
    maxWidth: 400,
  },
  {
    id: 'description',
    isSortable: true,
    label: 'types.table-header.description',
  },
  {
    id: 'businessLineId',
    isSortable: false,
    label: 'types.table-header.business-line',
  },
  {
    id: 'startDate',
    isSortable: true,
    label: 'types.table-header.start-date',
  },
  {
    id: 'endDate',
    isSortable: true,
    label: 'types.table-header.end-date',
  },
  {
    id: 'actions',
    isSortable: false,
    label: 'types.table-header.actions',
  },
]

function EnhancedTableHead(props) {
  const { t } = useTranslation('common')
  const { hasBusininessLine, onRequestSort, order, orderBy } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          if (!hasBusininessLine && column.id === 'businessLineId') {
            return null
          }
          return column.isSortable ? (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === column.id ? order : false}
              style={{ maxWidth: column.maxWidth }}
              sx={{ fontWeight: 'bold' }}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {t(column.label)}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={column.id}
              padding={column.disablePadding ? 'none' : 'normal'}
              style={{ maxWidth: column.maxWidth }}
              sx={{ fontWeight: 'bold' }}
            >
              {t(column.label)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  hasBusininessLine: PropTypes.bool,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

function EntityTemplate(props) {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()

  const {
    createEntityServiceCall,
    entityName,
    getEntitiesServiceCall,
    isProgram,
    onRowSelectCallback = () => {
      return null
    },
    updateAtomCall,
    updateEntityServiceCall,
  } = props

  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [page, setPage] = React.useState(0)
  const [totalRows, setTotalRows] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [openAddEditForm, setOpenAddEditForm] = React.useState(false)
  const [selectedEntity, setSelectedEntity] = React.useState({})
  const [rows, setRows] = useState([])
  const [loading, setLoading] = React.useState(false)
  const [entityListResponse, setEntityListResponse] = React.useState(null)
  const { handleApiError } = useErrorHandler()
  const businessLineOptions = useRecoilValue(programGroupAtom)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = (row) => {
    setSelectedEntity(row)
    onRowSelectCallback(row)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenAddModal = () => {
    setSelectedEntity({})
    setOpenAddEditForm(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddEditForm(false)
  }

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0

  const saveRow = (row, saveOperation) => {
    let newSavedEntity = null
    saveOperation
      .then((saveResult) => {
        newSavedEntity = saveResult
        setOpenAddEditForm(false)
        return getEntitiesServiceCall(page, rowsPerPage, `${orderBy},${order}`)
      })
      .then((getResult) => {
        const contentList = getResult.content
        // if list has more then 1 elements then move newly created entity to the top
        if (Array.isArray(contentList) && contentList.length > 1) {
          const filteredEntityList = contentList.filter((entity) => {
            return entity.id !== newSavedEntity.id
          })
          const updatedResult = { ...getResult, content: [newSavedEntity, ...filteredEntityList] }
          setEntityListResponse(updatedResult)
        } else {
          setEntityListResponse(getResult)
        }
        // set the focus on newly created entity
        handleClick(newSavedEntity)
        showSnackSuccess(t('api.save-success'))
      })
      .catch((error) => {
        handleApiError(error)
      })
  }

  const updateRow = (row) => {
    saveRow(row, updateEntityServiceCall(row.id, row))
  }

  const createEntity = (row) => {
    if (createEntityServiceCall) {
      saveRow(row, createEntityServiceCall(row))
    }
    // else {
    //   log.debug('Warning: Update function was not provided!')
    // }
  }

  const handleTerminateRow = (row, terminated) => {
    row.isTerminated = terminated
    row.startDate = row.startDate
      ? DateTime.fromFormat(row.startDate, IDHE_DATE_DISPLAY_FORMAT).toFormat(LOCAL_DATE_FORMAT)
      : null
    row.endDate =
      terminated && row.endDate
        ? DateTime.fromFormat(row.endDate, IDHE_DATE_DISPLAY_FORMAT).toFormat(LOCAL_DATE_FORMAT)
        : null
    updateRow(row)
  }

  const handleEditClicked = (row) => {
    setSelectedEntity(row)
    setOpenAddEditForm(true)
  }

  const handleSaveCallBack = (row) => {
    if (selectedEntity.id) {
      row.id = selectedEntity.id
      row.isTerminated = selectedEntity.isTerminated

      updateRow(row)
    } else {
      row.isTerminated = false
      createEntity(row)
    }
  }

  useEffect(() => {
    if (getEntitiesServiceCall) {
      setLoading(true)
      getEntitiesServiceCall(page, rowsPerPage, `${orderBy},${order}`)
        .then((response) => {
          setEntityListResponse(response)
        })
        .catch((error) => {
          handleApiError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [page, rowsPerPage, orderBy, order, getEntitiesServiceCall, handleApiError])

  useEffect(() => {
    if (entityListResponse) {
      setTotalRows(entityListResponse.totalElements)
      const rowData = entityListResponse.content.map((item) => {
        const startDate = item.startDate || item.effective?.startDate
        const endDate = item.endDate || item.effective?.endDate || null
        const isTerminated = item.effective ? item.effective.isTerminated : item.isTerminated
        const businessLineId = item.businessLineId || null
        return createRow(
          item.name,
          item.description,
          isoDateToDisplayFormatInUTC(startDate),
          endDate ? isoDateToDisplayFormatInUTC(endDate) : null,
          item.id,
          isTerminated,
          businessLineId
        )
      })
      setRows(rowData)
      let entityList = []
      entityListResponse.content?.forEach((val) => {
        entityList = [
          ...entityList,
          {
            businessLineId: val.businessLineId,
            description: val.description,
            endDate: val.endDate,
            id: val.id,
            isTerminated: val.isTerminated,
            name: val.name,
            startDate: val.startDate,
          },
        ]
      })
      updateAtomCall(entityList)
    } else {
      setRows([])
    }
  }, [entityListResponse, updateAtomCall])
  return (
    <>
      {openAddEditForm && (
        <AddEditDialog
          entity={selectedEntity}
          entityName={entityName}
          handleCloseCallback={handleCloseAddModal}
          isProgram={isProgram}
          openDialog={openAddEditForm}
          saveCallback={handleSaveCallBack}
        />
      )}
      <Box>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <h2>List of {entityName}s</h2>
          </Grid>
          <Grid item>
            <MISButton onClick={handleOpenAddModal}>
              {t('common.button.create')} {entityName}
            </MISButton>
          </Grid>
        </Grid>

        <Grid container>
          <Grid component={Paper} item xs={12}>
            <TablePagination
              component="div"
              count={totalRows}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
            <TableContainer sx={{ p: 2 }}>
              <Table aria-labelledby="tableTitle" size="small">
                <EnhancedTableHead
                  hasBusininessLine={isProgram}
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  rowCount={rows.length}
                />

                {!loading && rows && rows.length > 0 && (
                  <TableBody>
                    {rows.map((row, index) => {
                      if (row) {
                        const labelId = `enhanced-table-checkbox-${index}`
                        return (
                          <TableRow
                            hover
                            key={row.name}
                            onClick={() => handleClick(row)}
                            role="checkbox"
                            selected={row.id === selectedEntity.id}
                            tabIndex={-1}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              padding="none"
                              scope="row"
                              style={{ maxWidth: 300 }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.description}</TableCell>
                            {isProgram && (
                              <TableCell align="left">
                                {row.businessLineId
                                  ? businessLineOptions.find((x) => x.id === row.businessLineId)
                                      .name
                                  : ''}
                              </TableCell>
                            )}
                            <TableCell align="left">{row.startDate}</TableCell>
                            <TableCell align="left">{row.endDate}</TableCell>
                            <TableCell align="left">
                              <MISButton onClick={() => handleEditClicked(row, index)} size="small">
                                {t('common.button.edit')}
                              </MISButton>
                              <MISButton
                                onClick={() => handleTerminateRow(row, !row.isTerminated)}
                                size="small"
                                sx={{ marginLeft: '1rem' }}
                              >
                                {row.isTerminated
                                  ? t('common.button.activate')
                                  : t('common.button.terminate')}
                              </MISButton>
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        return null
                      }
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 49 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRows}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default EntityTemplate
