import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Dialog, DialogActions } from '@mui/material'
import { useSetRecoilState } from 'recoil'
import MISButton from 'common/components/MISButton'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import FollowUpTemplateComponent from 'modules/charting/components/templates/follow-up-template/FollowUpTemplateComponent'
import ReferralTemplateComponent from 'modules/charting/components/templates/referral-template/ReferralTemplateComponent'
import ClientRecordHeaderDetails from 'modules/client/ClientDetails/ClientRecordHeaderDetails'
import { isTemplateUpdatedState } from 'recoil/charting'
import {
  ChartingEntryControllerService,
  ClientControllerService,
  ClientDTO,
} from 'services/openapi'

type UserDashboardEntryProps = {
  id?: string
  clientId?: string
  entry?: TTemplateBlot
  onClose: () => void
}

const UserDashboardEntry = ({ clientId, entry, id, onClose }: UserDashboardEntryProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()

  const [client, setClient] = useState<ClientDTO | undefined>()
  const [templateData, setTemplateData] = useState(entry?.templateData || {})
  const setIsTemplateUpdated = useSetRecoilState(isTemplateUpdatedState)

  const saveEntry = useCallback(() => {
    if (clientId && id && entry?.templateRecordId && templateData)
      ChartingEntryControllerService.updateChartingTemplate(clientId, id, entry?.templateRecordId, {
        templateData,
      })
        .then(() => {
          setIsTemplateUpdated(true)
          onClose()
        })
        .catch((error) => handleApiError(error))
  }, [
    clientId,
    entry?.templateRecordId,
    handleApiError,
    id,
    onClose,
    setIsTemplateUpdated,
    templateData,
  ])

  useEffect(() => {
    if (clientId)
      ClientControllerService.getClient(clientId)
        .then((resp) => setClient(resp))
        .catch((error) => handleApiError(error))
  }, [clientId, handleApiError])

  useEffect(() => setTemplateData(entry?.templateData || {}), [entry])

  const getTemplateComponent = useCallback(() => {
    if (entry) {
      switch (entry.templateId) {
        case 'FollowUpTemplate':
          return (
            <FollowUpTemplateComponent
              {...templateData}
              onChange={(key, value) => setTemplateData({ ...templateData, [key]: value })}
            />
          )
        case 'ReferralTemplate':
          return (
            <ReferralTemplateComponent
              {...templateData}
              onChange={(key, value) => setTemplateData({ ...templateData, [key]: value })}
            />
          )
      }
    }
  }, [entry, templateData])

  if (!templateData) return null

  return (
    <Dialog maxWidth="lg" onClose={onClose} open>
      <Box sx={{ mt: 6 }}>
        {client && <ClientRecordHeaderDetails client={client} readOnly withNavigation />}
        <Box sx={{ mx: 3 }}>{getTemplateComponent()}</Box>
      </Box>
      <DialogActions>
        <MISButton color="primary" onClick={saveEntry} variant="contained">
          {t('common.button.save')}
        </MISButton>
      </DialogActions>
    </Dialog>
  )
}

export default UserDashboardEntry
