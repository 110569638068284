import { createSlice } from '@reduxjs/toolkit'

export interface HistoricalTriggerState {
  id: number | null
}

const initialState: HistoricalTriggerState = {
  id: null,
}

export const historicalTriggerSlice = createSlice({
  initialState,
  name: 'historicalTrigger',
  reducers: {
    setHistoricalTrigger: (state) => {
      state.id = Date.now()
    },
  },
})

export const { setHistoricalTrigger } = historicalTriggerSlice.actions
export default historicalTriggerSlice.reducer
