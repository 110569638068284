import { DateTime } from 'luxon'
/**
 *
 *
 * FORMATS
 *
 *
 */
// These are the formats to be displayed on the UI for non DatePicker field e.g simple static view of a field
export const IDHE_DATE_DISPLAY_FORMAT = 'yyyy-MMM-dd'
export const IDHE_DATE_TIME_DISPLAY_FORMAT = 'yyyy-MMM-dd HH:mm'
export const IDHE_DATE_TIME_SEC_DISPLAY_FORMAT = 'yyyy-MMM-dd HH:mm:ss'
export const IDHE_DATE_MONTH_DISPLAY_FORMAT = 'MMMM yyyy'
export const IDHE_TIME_SEC_DISPLAY_FORMAT = 'HH:mm:ss'
// This is the format used by backend services for localDates
export const LOCAL_DATE_FORMAT = 'yyyy-MM-dd'
/**
 *
 *
 * FUNCTION CONVERTERS
 *
 *
 */

export const convertMinsToHourMins = (mins: any) => {
  let minutes: number | string = mins % 60
  let hours: number | string = Math.floor(mins / 60)

  minutes = (minutes < 10 ? '0' : '') + minutes
  hours = (hours < 10 ? '0' : '') + hours

  return hours + ':' + minutes
}
/**
 *
 *
 * FUNCTION FORMATTERS
 *
 *
 */
export const localDateFormatToDisplayFormat = (localDateString: string) => {
  return DateTime.fromFormat(localDateString, LOCAL_DATE_FORMAT).toFormat(IDHE_DATE_DISPLAY_FORMAT)
}

export const isoFormatToDisplayFormatNoConvert = (localDateString: string) => {
  return DateTime.fromISO(localDateString, { setZone: true }).toFormat(IDHE_DATE_DISPLAY_FORMAT)
}

export const isoDateToDisplayFormat = (isoDateString: string) => {
  return DateTime.fromISO(isoDateString).toFormat(IDHE_DATE_DISPLAY_FORMAT)
}

export const isoDateToDisplayFormatInUTC = (isoDateString: string) => {
  return DateTime.fromISO(isoDateString, { zone: 'utc' }).toFormat(IDHE_DATE_DISPLAY_FORMAT)
}

export const isoDateToDisplayFormatWithTime = (isoDateString: any) => {
  return DateTime.fromISO(isoDateString).toFormat(IDHE_DATE_TIME_SEC_DISPLAY_FORMAT)
}

export const displayToIso = (localDateString: string) => {
  return DateTime.fromFormat(localDateString, IDHE_DATE_DISPLAY_FORMAT).toFormat(
    "yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
  )
}

export const getTimeFromDate = (isoDateString: string) => {
  return DateTime.fromISO(isoDateString).toFormat(IDHE_TIME_SEC_DISPLAY_FORMAT)
}

export const isoDateToLocalDisplayFormat = (isoDateString: any) => {
  return DateTime.fromISO(isoDateString).toFormat(LOCAL_DATE_FORMAT)
}

export const nowToApi = () => {
  return DateTime.fromJSDate(new Date()).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
}

export const formatDateToApi = (dateEvt: string | number | Date) => {
  return DateTime.fromJSDate(new Date(dateEvt)).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSSZZ")
}

export const dateTimeToDisplayFormat = (localDateString: DateTime) => {
  return localDateString.toFormat(IDHE_DATE_DISPLAY_FORMAT)
}

export const dateNowDisplayFormat = () => {
  DateTime.now().toFormat(LOCAL_DATE_FORMAT)
}

export const formatToLocalDateTime = (dateEvt: string | number | Date) => {
  return DateTime.fromJSDate(new Date(dateEvt)).toFormat("yyyy-MM-dd'T'HH:mm:ss")
}

/**
 *
 *
 * FUNCTIONS VALIDATORS
 *
 *
 */
export const isDateBeforeOrEqualDate = (isoDateString: string, isoDateStringComparedTo: string) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  const luxonDateComparedTo = DateTime.fromISO(isoDateStringComparedTo)
  return luxonDate.startOf('day') <= luxonDateComparedTo.startOf('day')
}

export const isDateAfterToday = (isoDateString: string) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  return luxonDate.startOf('day') > DateTime.now().startOf('day')
}

export const isDateBeforeOrEqualToday = (isoDateString: string) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  return luxonDate.startOf('day') <= DateTime.now().startOf('day')
}

export const isDateBeforeToday = (isoDateString: any) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  return luxonDate.startOf('day') < DateTime.now().startOf('day')
}

export const isDateBetween = (
  isoDateString: string,
  isoDateStringFrom: string,
  isoDateStringTo: string
) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  const luxonDateFrom = DateTime.fromISO(isoDateStringFrom)
  const luxonDateTo = DateTime.fromISO(isoDateStringTo)
  return (
    luxonDateFrom.startOf('day') <= luxonDate.startOf('day') &&
    luxonDateTo.startOf('day') >= luxonDate.startOf('day')
  )
}

export const convertDateToLocalFormat = (dateValue: DateTime) => {
  return dateValue.toFormat(LOCAL_DATE_FORMAT)
}

export const dateNowIsoString = () => {
  return DateTime.now().toISO() as string
}

export const isDate1BeforeDate2 = (isoDateString: string, isoDateStringComparedTo: string) => {
  const luxonDate = DateTime.fromISO(isoDateString)
  const luxonDateComparedTo = DateTime.fromISO(isoDateStringComparedTo)
  return luxonDate.startOf('day') < luxonDateComparedTo.startOf('day')
}

/*
checks the overlapping dates which are passed to array.
eg[{startDate:'..', endDate:'..}, {startDate:'..', endDate:'..}]
*/
export const isOverlappingDatesInDateArray = (dateArray: any) => {
  let i, j
  if (dateArray.length < 2) return false
  for (i = 0; i < dateArray.length - 1; i += 1) {
    for (j = i + 1; j < dateArray.length; j += 1) {
      if (
        isOverlappingDates(
          dateArray[i].startDate,
          dateArray[i].endDate,
          dateArray[j].startDate,
          dateArray[j].endDate
        )
      )
        return true
    }
  }
  return false
}

// check if two dates are overlapping
export const isOverlappingDates = (
  isoDateStringFrom1: string,
  isoDateStringTo1: string,
  isoDateStringFrom2: string,
  isoDateStringTo2: string
) => {
  if (!isoDateStringTo1 && !isoDateStringTo2) {
    return true
  }
  let overLap = false

  if (!isoDateStringTo1 && isoDateStringTo2) {
    overLap = !isDate1BeforeDate2(isoDateStringTo2, isoDateStringFrom1)
    return overLap
  }
  if (isoDateStringTo1 && !isoDateStringTo2) {
    overLap = !isDate1BeforeDate2(isoDateStringTo1, isoDateStringFrom2)
    return overLap
  }
  return isDateRangeOverlaps(
    isoDateStringFrom1,
    isoDateStringTo1,
    isoDateStringFrom2,
    isoDateStringTo2
  )
}

const isDateRangeOverlaps = (
  isoDateStringFrom1: string,
  isoDateStringTo1: string,
  isoDateStringFrom2: string,
  isoDateStringTo2: string
) => {
  if (
    isDateBeforeOrEqualDate(isoDateStringFrom1, isoDateStringFrom2) &&
    isDateBeforeOrEqualDate(isoDateStringFrom2, isoDateStringTo1)
  )
    return true // date2 starts in date1
  if (
    isDateBeforeOrEqualDate(isoDateStringFrom1, isoDateStringTo2) &&
    isDateBeforeOrEqualDate(isoDateStringTo2, isoDateStringTo1)
  )
    return true // date2 ends in date1
  if (
    isDate1BeforeDate2(isoDateStringFrom2, isoDateStringFrom1) &&
    isDate1BeforeDate2(isoDateStringTo1, isoDateStringTo2)
  )
    return true // date1 in date2
  return false
}

// Calculate next reminder date
export enum TimeUnit {
  Seconds = 's',
  Minutes = 'min',
  Hours = 'h',
  Days = 'd',
  Weeks = 'wk',
  Months = 'mo',
  Years = 'a',
}

interface Reminder {
  interval: number
  unit: TimeUnit
}

const calculateDaysInCurrentMonth = (currentDate: Date): number => {
  const nextMonth = new Date(currentDate)
  nextMonth.setMonth(currentDate.getMonth() + 1)
  nextMonth.setDate(0) // Move to the last day of the current month
  return nextMonth.getDate()
}

const calculateDateByYearInterval = (currentDate: Date, interval: number): string => {
  const nextYear = new Date(currentDate)
  nextYear.setFullYear(currentDate.getFullYear() + interval)
  nextYear.setDate(nextYear.getDate() - 1) // Move to the last day of the previous year
  return nextYear.toDateString()
}

export const calculateNextReminderDate = (startDate: string, reminder: Reminder) => {
  const { interval, unit } = reminder
  const millisecondsInASecond = 1000
  const secondsInAMinute = 60
  const minutesInAnHour = 60
  const hoursInADay = 24

  const currentDate = startDate ? new Date(startDate) : new Date()
  let multiplier = 1

  switch (unit) {
    case TimeUnit.Seconds:
      multiplier = millisecondsInASecond
      break
    case TimeUnit.Minutes:
      multiplier = secondsInAMinute * millisecondsInASecond
      break
    case TimeUnit.Hours:
      multiplier = minutesInAnHour * secondsInAMinute * millisecondsInASecond
      break
    case TimeUnit.Days:
      multiplier = hoursInADay * minutesInAnHour * secondsInAMinute * millisecondsInASecond
      break
    case TimeUnit.Weeks:
      multiplier = 7 * hoursInADay * minutesInAnHour * secondsInAMinute * millisecondsInASecond
      break
    case TimeUnit.Months: {
      const daysInCurrentMonth = calculateDaysInCurrentMonth(currentDate)
      multiplier =
        daysInCurrentMonth *
        hoursInADay *
        minutesInAnHour *
        secondsInAMinute *
        millisecondsInASecond
      break
    }
    case TimeUnit.Years: {
      return calculateDateByYearInterval(currentDate, interval)
    }
    default:
      throw new Error('Invalid time unit')
  }

  const nextReminderDate = new Date(currentDate.getTime() + interval * multiplier)
  return nextReminderDate.toDateString()
}
