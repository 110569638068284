import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'
import { visuallyHidden } from '@mui/utils'
import { DateTime } from 'luxon'
import MISChip from 'common/components/form/MISChip'
import MISHighPrioIcon from 'common/components/icons/MISHighPrioIcon'
import MISLowPrioIcon from 'common/components/icons/MISLowPrioIcon'
import MISMedPrioIcon from 'common/components/icons/MISMedPrioIcon'
import { isDateBeforeOrEqualDate, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getClientFullName } from 'modules/shared/clientUtils'
import { ClientControllerService, ClientDTO } from 'services/openapi'
import { FOLLOW_UP_URGENCY } from 'services/terminologyConstants'
import { selectTerminology } from 'store/selectors/terminology'
import { TDashboardEntry } from './UserDashboard'

type UserDashboardTableProps = {
  entries: TDashboardEntry[]
  onSelect: (id: string) => void
}

type Order = 'asc' | 'desc'
type OrderBy =
  | 'dueDate'
  | 'type'
  | 'description'
  | 'clientName'
  | 'priority'
  | 'state'
  | 'templateDisplayName'

function getDueDateComparator(a: TDashboardEntry, b: TDashboardEntry, order: Order) {
  if (!a.dueDate && b.dueDate) return order === 'asc' ? 1 : -1
  else if (a.dueDate && !b.dueDate) return order === 'asc' ? -1 : 1
  else if (!a.dueDate && !b.dueDate) return 0
  else {
    if (order === 'asc')
      return isDateBeforeOrEqualDate(a.dueDate as string, b.dueDate as string) ? -1 : 1
    else return isDateBeforeOrEqualDate(b.dueDate as string, a.dueDate as string) ? -1 : 1
  }
}

function stringComparator(a: string, b: string, order: Order) {
  if (!a && b) return order === 'asc' ? 1 : -1
  else if (a && !b) return order === 'asc' ? -1 : 1
  else if (!a && !b) return 0
  else {
    if (order === 'asc') return a < b ? -1 : 1
    else return b < a ? -1 : 1
  }
}

function getPriorityOrder(code: string | undefined) {
  switch (code) {
    case '1251527002':
      return '1'
    case '394848005':
      return '2'
    case '394849002':
      return '3'
    default:
      return ''
  }
}

function getComparator(
  clients: { [key: string]: ClientDTO } | undefined,
  order: Order,
  orderBy: OrderBy
) {
  if (orderBy === 'dueDate') {
    return (a: TDashboardEntry, b: TDashboardEntry) => getDueDateComparator(a, b, order)
  }
  if (orderBy === 'priority') {
    return (a: TDashboardEntry, b: TDashboardEntry) => {
      const a1 = getPriorityOrder(a.priority)
      const b1 = getPriorityOrder(b.priority)
      return stringComparator(a1, b1, order)
    }
  }

  if (orderBy === 'clientName') {
    return (a: TDashboardEntry, b: TDashboardEntry) => {
      let clientFullName1 = ''
      const clientId1 = a.clientId
      if (clientId1 && clients && clients[clientId1]) {
        clientFullName1 = getClientFullName(clients[clientId1])
      }
      let clientFullName2 = ''
      const clientId2 = b.clientId
      if (clientId2 && clients && clients[clientId2]) {
        clientFullName2 = getClientFullName(clients[clientId2])
      }

      return stringComparator(clientFullName1, clientFullName2, order)
    }
  }
  if (orderBy === 'state' || orderBy === 'description' || orderBy === 'templateDisplayName') {
    return (a: TDashboardEntry, b: TDashboardEntry) => {
      const a1 = a[orderBy] || ''
      const b1 = b[orderBy] || ''
      return stringComparator(a1, b1, order)
    }
  }
}
const getEntries = (
  entries: TDashboardEntry[],
  clients: { [key: string]: ClientDTO } | undefined,
  order: Order,
  orderBy: OrderBy,
  period: 'day' | 'week' | 'month' | null,
  page: number,
  rowsPerPage: number
) => {
  const poop = entries
    .filter((each) => {
      if (period === 'day') {
        if (!each.dueDate) return false
        const dueDate = DateTime.fromISO(each.dueDate).toLocal()
        return dueDate.hasSame(DateTime.now().toLocal(), 'day')
      }
      if (period === 'week') {
        if (!each.dueDate) return false
        const dueDate = DateTime.fromISO(each.dueDate).toLocal()
        return (
          DateTime.now().toLocal().startOf('week') <= dueDate &&
          dueDate <= DateTime.now().toLocal().endOf('week')
        )
      }
      if (period === 'month') {
        if (!each.dueDate) return false
        const dueDate = DateTime.fromISO(each.dueDate).toLocal()
        return (
          DateTime.now().toLocal().startOf('month') <= dueDate &&
          dueDate <= DateTime.now().toLocal().endOf('month')
        )
      }
      return true
    })
    .sort(getComparator(clients, order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  return poop
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme()
  const { count, onPageChange, page, rowsPerPage } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        aria-label="first page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton aria-label="previous page" disabled={page === 0} onClick={handleBackButtonClick}>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        aria-label="next page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        aria-label="last page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const UserDashboardTable = ({ entries, onSelect }: UserDashboardTableProps) => {
  const { t } = useTranslation('common')
  const terminology = useSelector(selectTerminology)
  const { handleApiError } = useErrorHandler()

  const [clientsByIds, setClientsByIds] = useState<{ [key: string]: ClientDTO } | undefined>()
  const [period, setPeriod] = useState<'day' | 'week' | 'month' | null>(null)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<OrderBy>('dueDate')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const followUpUrgency = useMemo(
    () => terminology.find((term) => term.setName === FOLLOW_UP_URGENCY)?.value || [],
    [terminology]
  )

  const handlePeriod = useCallback(
    (_: React.MouseEvent<HTMLElement>, period: 'day' | 'week' | 'month' | null) => {
      setPeriod(period)
    },
    []
  )

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback((event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const renderPrioIcon = useCallback((code) => {
    switch (code) {
      case '1251527002':
        return <MISLowPrioIcon />
      case '394848005':
        return <MISMedPrioIcon />
      case '394849002':
        return <MISHighPrioIcon />
      default:
        return ''
    }
  }, [])

  const renderStateChip = useCallback((state) => {
    //colors here are set in such a way that Draft, complete and voided have different colors and everything else comes under "in progress"
    let circleColor = ''
    let pillColor = ''
    switch (state) {
      case 'Draft': {
        circleColor = '#8f60bf'
        pillColor = '#8f60bf30'
        break
      }
      case 'Complete':
        circleColor = '#608fbf'
        pillColor = '#608fbf30'
        break
      case 'Voided':
        circleColor = '#91918e'
        pillColor = '#e3e2e0'
        break
      default:
        circleColor = '#f7a128'
        pillColor = '#f7a12830'
        break
    }

    return (
      <MISChip
        avatar={<Avatar />}
        label={state}
        sx={{
          '& .MuiAvatar-fallback': {
            visibility: 'hidden',
          },
          '& .MuiChip-avatar': {
            backgroundColor: circleColor,
            height: 8,
            width: 8,
          },
          backgroundColor: pillColor,
          color: 'black',
        }}
      />
    )
  }, [])

  useEffect(() => {
    const clientIds = entries.filter((each) => each.clientId).map((each) => each.clientId as string)
    if (clientIds.length > 0)
      ClientControllerService.searchClientByIds(clientIds)
        .then((resp) => setClientsByIds(resp as unknown as { [key: string]: ClientDTO }))
        .catch((error) => handleApiError(error))
  }, [entries, handleApiError])

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <Typography
          sx={{
            background: '0#fff',
            fontSize: '18px',
            fontWeight: 'bold',
            pl: 2,
            pr: 9,
            pt: 2,
            width: 'auto',
          }}
          variant="h1"
        >
          {t('user-dashboard.dashboard')}
        </Typography>
        <Box sx={{ alignItems: 'center', display: 'flex', width: '10%' }}>
          <Avatar
            sx={{
              bgcolor: 'black',
              color: 'white',
              fontSize: '12px',
              height: 25,
              width: 25,
            }}
          >
            {entries.filter((e) => e.template.templateData.state === 'Pending').length}
          </Avatar>
          <Typography sx={{ ml: 1 }}>{t('user-dashboard.follow-ups')}</Typography>
        </Box>
        <Box sx={{ alignItems: 'center', display: 'flex', width: '20%' }}>
          <Avatar
            sx={{
              bgcolor: 'black',
              color: 'white',
              fontSize: '12px',
              height: 25,
              width: 25,
            }}
          >
            {entries.filter((e) => e.template.templateData.state === 'Booked').length}
          </Avatar>
          <Typography sx={{ ml: 1 }}>{t('user-dashboard.referrals')}</Typography>
        </Box>
        <ToggleButtonGroup
          aria-label="due date period"
          exclusive
          onChange={handlePeriod}
          sx={{ pb: 1, pt: 1 }}
          value={period}
        >
          <ToggleButton aria-label="day" size="small" value="day">
            {t('user-dashboard.day')}
          </ToggleButton>
          <ToggleButton aria-label="week" size="small" value="week">
            {t('user-dashboard.week')}
          </ToggleButton>
          <ToggleButton aria-label="month" size="small" value="month">
            {t('user-dashboard.month')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Table
        aria-label="simple table"
        sx={{
          '& .MuiTableCell-root': { height: '40px', paddingBottom: '1px', paddingTop: '1px' },
          minWidth: 650,
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: 'grey', width: '10%' }}>
              <TableSortLabel
                active={orderBy === 'templateDisplayName'}
                direction={order}
                onClick={() => handleRequestSort('templateDisplayName')}
              >
                {t('user-dashboard.table.header.type')}
                {orderBy === 'templateDisplayName' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'grey', width: '40%' }}>
              <TableSortLabel
                active={orderBy === 'description'}
                direction={order}
                onClick={() => handleRequestSort('description')}
              >
                {t('user-dashboard.table.header.description')}
                {orderBy === 'description' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'grey', width: '10%' }}>
              <TableSortLabel
                active={orderBy === 'clientName'}
                direction={order}
                onClick={() => handleRequestSort('clientName')}
              >
                {t('user-dashboard.table.header.client')}
                {orderBy === 'clientName' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'grey', width: '10%' }}>
              <TableSortLabel
                active={orderBy === 'priority'}
                direction={order}
                onClick={() => handleRequestSort('priority')}
              >
                {t('user-dashboard.table.header.priority')}
                {orderBy === 'priority' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'grey', width: '10%' }}>
              <TableSortLabel
                active={orderBy === 'state'}
                direction={order}
                onClick={() => handleRequestSort('state')}
              >
                {t('user-dashboard.table.header.state')}
                {orderBy === 'state' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ color: 'grey', width: '10%' }}>
              <TableSortLabel
                active={orderBy === 'dueDate'}
                direction={order}
                onClick={() => handleRequestSort('dueDate')}
              >
                {t('user-dashboard.table.header.due-date')}
                {orderBy === 'dueDate' ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {getEntries(entries, clientsByIds, order, orderBy, period, page, rowsPerPage).map(
            (
              {
                clientId,
                description,
                dueDate,
                id,
                priority,
                state,
                template,
                templateDisplayName,
              },
              index
            ) => (
              <TableRow
                key={template.templateRecordId}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: index % 2 === 0 ? 'white' : '#00000008',
                }}
              >
                <TableCell sx={{ minWidth: '120px' }}>{templateDisplayName}</TableCell>
                <TableCell component="th" scope="row" sx={{ width: '20%' }}>
                  <Tooltip arrow title={description}>
                    <Typography
                      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      variant="body2"
                    >
                      {description}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {clientId && clientsByIds && clientsByIds[clientId]
                    ? getClientFullName(clientsByIds[clientId])
                    : ''}
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {followUpUrgency.find((each) => each.code === priority)?.name &&
                    renderPrioIcon(priority)}
                </TableCell>
                <TableCell sx={{ minWidth: '120px' }}>{renderStateChip(state)}</TableCell>
                <TableCell sx={{ minWidth: '120px' }}>
                  {dueDate ? isoDateToDisplayFormat(dueDate) : ''}
                </TableCell>
                <TableCell sx={{ minWidth: '60px' }}>
                  <IconButton onClick={() => onSelect(template.templateRecordId)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              colSpan={12}
              count={entries.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              slotProps={{
                select: { inputProps: { 'aria-label': 'rows per page' }, native: true },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default UserDashboardTable
