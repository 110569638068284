import { Component, MouseEvent as ReactMouseEvent } from 'react'
import { SketchPicker } from 'react-color'
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker'
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Fab, Grid, IconButton, Popover, Stack, Tooltip } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import BodyChart from 'assets/images/body-chart.png'
import MISTextField from 'common/components/form/MISTextField'
import { ITemplate } from '../../blots/TemplateBlot'

type TAnnotation = {
  annotation: string
  id: string
  markerColor: string
}

export type BodyTemplateState = {
  annotations?: TAnnotation[]
  image?: string
  imageSketchPickerAnchorEl?: Element
  markerColor: string
  markers?: Marker[]
  markerSketchPickerAnchorEls: (Element | undefined)[]
}

type BodyTemplateProps = { data?: BodyTemplateState }

export default class BodyNewTemplate
  extends Component<BodyTemplateProps, BodyTemplateState>
  implements ITemplate
{
  type = 'BodyTemplate'
  state: BodyTemplateState = { markerColor: '#000', markerSketchPickerAnchorEls: [] }

  constructor(props: BodyTemplateProps) {
    super(props)
    this.state = {
      ...props.data,
      markerColor: '#000',
      markerSketchPickerAnchorEls: props.data?.annotations
        ? new Array(props.data.annotations.length).fill(undefined)
        : [],
    }
  }

  getData = () => {
    return {
      annotations: this.state.annotations,
      image: this.state.image,
      markers: this.state.markers,
    }
  }

  handleAddMarker = (marker: Marker) => {
    this.setState({
      annotations: [
        ...(this.state.annotations || []),
        { annotation: '', id: uuidv4(), markerColor: this.state.markerColor },
      ],
      markers: [...(this.state.markers || []), marker],
    })
  }

  handleMarkerColorClose = (index: number) => {
    const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
    newMarkerSketchPickerAnchorEls.splice(index, 1, undefined)
    this.setState({
      markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls,
    })
  }

  handleMarkerColorOpen = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
    newMarkerSketchPickerAnchorEls[index] = e.currentTarget
    this.setState({
      markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls,
    })
  }

  handleRemoveMarker = (index: number) => {
    if (
      this.state.annotations &&
      this.state.annotations.length > 0 &&
      this.state.markers &&
      this.state.markers.length > 0
    ) {
      const annotations = [...this.state.annotations]
      const markers = [...this.state.markers]
      annotations.splice(index, 1)
      markers.splice(index, 1)
      this.setState({ annotations, markers })
    }
  }

  handleUpdateAnnotation = (value: string, index: number) => {
    if (this.state.annotations && this.state.annotations.length > 0) {
      const annotations = [...this.state.annotations]
      annotations[index] = { ...annotations[index], annotation: value }
      this.setState({ annotations })
    }
  }

  handleUpdateMarkerColor = (value: string, index: number) => {
    if (this.state.annotations && this.state.annotations.length > 0) {
      const annotations = [...this.state.annotations]
      annotations[index] = { ...annotations[index], markerColor: value }
      const newMarkerSketchPickerAnchorEls = this.state.markerSketchPickerAnchorEls
      newMarkerSketchPickerAnchorEls.splice(index, 1, undefined)
      this.setState({ annotations, markerSketchPickerAnchorEls: newMarkerSketchPickerAnchorEls })
    }
  }

  save = async () => {
    console.log('SAVEDDDD', this.type)
  }

  cancel = async () => {
    console.log('Cancel called', this.type)
  }

  CustomMarker = (props: MarkerComponentProps) => {
    const markerColor =
      this.state.annotations?.[props.itemNumber.valueOf()].markerColor || this.state.markerColor
    return (
      <Box
        className="custom-marker"
        sx={{
          alignItems: 'center',
          border: 2,
          borderColor: markerColor,
          borderRadius: '50%',
          color: markerColor,
          fontSize: '16px',
          fontWeight: 'bold',
          height: '28px',
          textAlign: 'center',
          width: '28px',
        }}
      >
        {`${props.itemNumber.valueOf() + 1}`}
      </Box>
    )
  }

  render() {
    return (
      <Box sx={{ mb: 1 }}>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Box sx={{ cursor: 'default', maxHeight: 550, maxWidth: 612 }}>
              <ImageMarker
                markerComponent={this.CustomMarker}
                markers={this.state.markers || []}
                onAddMarker={this.handleAddMarker}
                src={BodyChart}
              />
            </Box>
            <Stack spacing={1}>
              <Fab
                aria-describedby="image-sketch-picker-popover"
                onClick={(e) => this.setState({ imageSketchPickerAnchorEl: e.currentTarget })}
                size="small"
                sx={{ backgroundColor: this.state.markerColor }}
              />
              <Tooltip title="Clear annotations">
                <Fab
                  onClick={() => this.setState({ annotations: undefined, markers: undefined })}
                  size="small"
                >
                  <CloseIcon />
                </Fab>
              </Tooltip>
              <Popover
                anchorEl={this.state.imageSketchPickerAnchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                id="image-sketch-picker-popover"
                onClose={() => this.setState({ imageSketchPickerAnchorEl: undefined })}
                open={Boolean(this.state.imageSketchPickerAnchorEl)}
              >
                <SketchPicker
                  color={this.state.markerColor}
                  onChange={(color) =>
                    this.setState({
                      imageSketchPickerAnchorEl: undefined,
                      markerColor: color.hex,
                    })
                  }
                />
              </Popover>
            </Stack>
          </Stack>
          {this.state.annotations && !!(this.state.annotations.length > 0) && (
            <>
              {this.state.annotations.map((annotation, index) => (
                <Grid alignItems="center" container justifyContent="left" key={annotation.id}>
                  <Grid item sx={{ pr: 2 }} xs={8}>
                    <MISTextField
                      defaultValue={this.state.annotations?.[index]?.annotation}
                      id={`annotation-${index + 1}`}
                      label={`Annotation ${index + 1}`}
                      multiline
                      onChange={(e) => this.handleUpdateAnnotation(e.target.value, index)}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="change marker color"
                      onClick={(e) => this.handleMarkerColorOpen(e, index)}
                    >
                      <CircleIcon
                        aria-describedby="marker-sketch-picker-popover"
                        htmlColor={annotation.markerColor}
                      />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => this.handleRemoveMarker(index)}>
                      <DeleteIcon htmlColor="#000" />
                    </IconButton>
                  </Grid>
                  <Popover
                    anchorEl={this.state.markerSketchPickerAnchorEls[index]}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    id="marker-sketch-picker-popover"
                    onClose={() => this.handleMarkerColorClose(index)}
                    open={Boolean(this.state.markerSketchPickerAnchorEls[index])}
                  >
                    <SketchPicker
                      color={annotation.markerColor}
                      onChange={(color) => this.handleUpdateMarkerColor(color.hex, index)}
                    />
                  </Popover>
                </Grid>
              ))}
            </>
          )}
        </Stack>
      </Box>
    )
  }
}
