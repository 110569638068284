import axios from 'axios'
import { DomainType } from 'core/Types'
import {
  CancelablePromise,
  CodedConceptDto,
  CodedRef,
  User,
  UserControllerService,
  UserInfo,
} from 'services/openapi'

export interface ErrorType {
  field: string
  message: string
}

export const getError = (errors: ErrorType[], field: string) => {
  const error = errors.find((error: ErrorType) => error.field === field)
  return error ? error.message : ''
}

export const hasError = (errors: ErrorType[], field: string) => {
  const error = errors.find((error: ErrorType) => error.field === field)
  return !!error
}

export const getFormattedOptions = (options: CodedConceptDto[], useIdAsValue = false) => {
  return options?.map((option: CodedConceptDto) => {
    return {
      label: option.name as string,
      value: useIdAsValue ? (option.id as string) : option,
    }
  })
}

export const getFormattedSortedOptionsWithDesc = (options: CodedConceptDto[]) => {
  const gonnaSort = [...options]
  const sortedOptions = gonnaSort?.sort((a, b) => {
    if (!a.description) return 1
    if (!b.description) return -1

    if (a.description < b.description) {
      return -1
    }
    if (a.description > b.description) {
      return 1
    }
    return 0
  })

  return sortedOptions?.map((option: CodedConceptDto) => {
    return {
      label: option.description,
      value: option,
    }
  })
}

export const getRowTitle = (arr: string[]) => {
  return [...arr].filter(Boolean).join(' | ').trim()
}

export const getValueSetType = (arr: CodedRef[], type: CodedRef) => {
  return arr?.find(({ code }: CodedRef) => code === type.code)
}

export const getUserInfoFromUsernames = async (
  usernames: string[],
  userInfo: Map<string, UserInfo>
) => {
  if (usernames.length === 0) return null
  const promises: CancelablePromise<User>[] = []
  usernames.forEach((name) => promises.push(UserControllerService.findUserBy(undefined, name)))
  const responses = await Promise.all(promises)
  responses.forEach((user) => {
    if (!userInfo.has(user.id as string)) userInfo.set(user.id as string, user as UserInfo)
  })
  return userInfo
}

export const getDomainNameById = (domains: DomainType[], domainId: string) => {
  let domainName: string | undefined = ''
  if (domainId) {
    domainName = domains.find((domain: DomainType) => domain.id === domainId)?.domainName
  }
  return domainName || ''
}

export const isNumeric = (n: number) => {
  return !isNaN(parseFloat(n.toString())) && isFinite(n)
}

export const hasDuplicateObjects = (array: object[]): boolean => {
  const seenObjects = new Set<string>()
  for (const obj of array) {
    const objString = JSON.stringify(obj)
    if (seenObjects.has(objString)) {
      return true
    }
    seenObjects.add(objString)
  }
  return false
}

export const downloadFile = async (id, token, name) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents/${id}/file?base64Data=false`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }
    )

    const blobUrl = window.URL.createObjectURL(response.data)
    const a = document.createElement('a')
    a.href = blobUrl
    a.download = name || 'downloaded_file'
    document.body.appendChild(a)
    a.click()

    window.URL.revokeObjectURL(blobUrl)
    document.body.removeChild(a)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}
