import './ClientRecordHeader.scss'

import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, Chip, Link, Stack, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { isDateBeforeOrEqualToday, isoDateToDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { getClientAgeString, getClientFullName } from 'modules/shared/clientUtils'
import { alertsAtom } from 'recoil/atoms'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientAlertControllerService,
  ClientDTO,
  ClientIdentifier,
  CodedRef,
  PrivacyDirectiveControllerService,
} from 'services/openapi'
import {
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MEMBER_STATUS_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
  VALUESET,
} from 'services/terminologyConstants'
import { setClientAlerts, setClientPrivacyDirectives } from 'store/reducers/client'
import { selectClientAlerts, selectClientPrivacyDirectives } from 'store/selectors/client'
import PrivacyDirectivesPopover from './ClientPrivacyDirectives/PrivacyDirectivesPopover'

type ClientRecordHeaderDetailsProps = {
  client: ClientDTO
  readOnly?: boolean
  withNavigation?: boolean
}

const ClientRecordHeaderDetails = ({
  client,
  readOnly,
  withNavigation,
}: ClientRecordHeaderDetailsProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const clientAlerts = useSelector(selectClientAlerts)
  const clientPrivacyDirectives = useSelector(selectClientPrivacyDirectives)
  const alerts = useRecoilValue(alertsAtom)
  const clientStatusOptions = useRecoilValue(terminologySelector(MEMBER_STATUS_VOCAB_NAME))
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const identifierTypeOptions = useRecoilValue(
    terminologySelector(MEMBER_IDENTIFIER_TYPE_VOCAB_NAME)
  )

  const getStatus = useCallback(
    (status: CodedRef) => {
      return clientStatusOptions.find((option) => status?.code === option.code)?.name
    },
    [clientStatusOptions]
  )

  const getIdentifierValue = useCallback(
    (identifierKey: string) => {
      const identifier =
        identifierTypeOptions?.find((option) => option.name === identifierKey) || null

      return (
        identifier &&
        client.clientIdentifiers?.find(
          (obj: ClientIdentifier) =>
            obj.type?.code === identifier.code &&
            obj.type?.codeSystemOid === identifier.codeSystemOid
        )?.value
      )
    },
    [client.clientIdentifiers, identifierTypeOptions]
  )

  useEffect(() => {
    const getClientAlerts = async (clientId: string) => {
      try {
        const response = await ClientAlertControllerService.getClientAlerts(clientId)
        dispatch(setClientAlerts(response))
      } catch (err) {
        handleApiError(err)
      }
    }
    if (client.id) getClientAlerts(client.id)
  }, [client, dispatch, handleApiError])

  useEffect(() => {
    const getClientPrivacyDirectives = async (clientId: string) => {
      try {
        const directives = await PrivacyDirectiveControllerService.getDirectives(
          clientId,
          true,
          undefined,
          undefined,
          ['auditInfo']
        )
        if (!directives?.content?.length) {
          dispatch(setClientPrivacyDirectives(undefined))
          return
        }
        const directive = directives.content[0]
        dispatch(setClientPrivacyDirectives(directive))
      } catch (error) {
        handleApiError(error)
      }
    }
    if (client.id && !readOnly) getClientPrivacyDirectives(client.id)
  }, [client, dispatch, handleApiError, readOnly])

  const isDisplayAlerts = useCallback(() => {
    return (
      clientAlerts &&
      clientAlerts?.length > 0 &&
      clientAlerts.filter(
        (alert) => !(alert?.effective?.endDate && isDateBeforeOrEqualToday(alert.effective.endDate))
      ).length > 0
    )
  }, [clientAlerts])

  return (
    <Stack>
      <Box className="client-record-header">
        <Box className="panel left">
          <Avatar
            alt="Avatar Image"
            className="avatar"
            src="https://wallpapercave.com/dwp2x/wp9061276.jpg"
          >
            <PersonIcon className="icon" />
          </Avatar>
        </Box>
        <Box className="panel right">
          <Stack spacing={0}>
            <Typography className="title">{getClientFullName(client)}</Typography>
            {client.status && client.status?.code !== VALUESET.MEMBER_STATUS.ACTIVE.code && (
              <Chip label={getStatus(client.status)} sx={{ mx: 0.25 }} />
            )}
            {clientPrivacyDirectives &&
              clientPrivacyDirectives?.directive?.code === 'RESTRICTED' && (
                <Chip label="Restricted" sx={{ mx: 0.25 }} />
              )}
            {!readOnly && <PrivacyDirectivesPopover />}

            <Typography className="name">
              <span>{t('client-header.gender-label')}: </span>
              {client.gender &&
                genderOptions?.find((option) => option.code === client.gender?.code)?.name}
            </Typography>
            <Typography className="name">
              <span>{t('client-header.pronouns-label')}: </span>
              {client.pronouns &&
                pronounsOptions?.find((option) => option.code === client.pronouns?.code)?.name}
            </Typography>
            <Typography className="name">
              <span>{t('client-header.birthdate-label')}: </span>
              {client.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''}
            </Typography>

            <Typography className="name">
              <span>{t('client-header.age-label')}: </span>
              {client.birthdate ? getClientAgeString(client.birthdate) : ''}
            </Typography>
            <Typography className="name">
              <span>{t('client-header.client-file-label')}: </span>
              {withNavigation ? (
                <Link
                  href={`/clients/client-record/${client.id}`}
                  onClick={(event) => {
                    event.preventDefault()
                    navigate(`/clients/client-record/${client.id}`)
                  }}
                  underline="hover"
                  variant="inherit"
                >
                  {client.fileNumber || ''}
                </Link>
              ) : (
                client.fileNumber || ''
              )}
            </Typography>
            <Typography className="name">
              <span>{t('client-header.status-card-label')}: </span>
              {getIdentifierValue('Indian Registry Status Number')}
            </Typography>
          </Stack>
        </Box>
      </Box>
      {isDisplayAlerts() && (
        <Box sx={{ marginBottom: 1, marginLeft: 8, marginTop: -3 }}>
          {clientAlerts &&
            clientAlerts?.length > 0 &&
            clientAlerts
              .filter(
                (alert) =>
                  !(alert?.effective?.endDate && isDateBeforeOrEqualToday(alert.effective.endDate))
              )
              .map((alert, index) => {
                const alertObj = alerts.find((a) => a?.id === alert?.alertId)
                return <Chip key={index} label={alertObj?.code} sx={{ height: '24px', mx: 0.25 }} />
              })}
        </Box>
      )}
    </Stack>
  )
}

export default ClientRecordHeaderDetails
