import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { DateTime } from 'luxon'
import { useRecoilValue } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { IDHE_DATE_DISPLAY_FORMAT } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import { programGroupAtom } from 'recoil/atoms'
import MISSelectDropdown from './form/MISSelectDropdown'

export default function AddEditDialog(props) {
  const { t } = useTranslation('common')

  const { entity, entityName, handleCloseCallback, isProgram, openDialog, saveCallback } = props

  const [name, setName] = useState(entity && entity.name ? entity.name : '')
  const [description, setDescription] = useState(
    entity && entity.description ? entity.description : ''
  )
  const [startDate, setStartDate] = useState(
    entity && entity.startDate
      ? DateTime.fromFormat(entity.startDate, IDHE_DATE_DISPLAY_FORMAT)
      : null
  )

  const [endDate, setEndDate] = useState(
    entity && entity.endDate ? DateTime.fromFormat(entity.endDate, IDHE_DATE_DISPLAY_FORMAT) : null
  )

  const businessLineOptions = useRecoilValue(programGroupAtom)

  const [businessLineId, setBusinessLineId] = useState(null)

  const { buildError, getError, hasError, isError, setErrorList } = useErrorHandler()

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      handleCloseCallback()
    }
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  }

  const handleStartDateChange = (value) => {
    setStartDate(value ? DateTime.fromISO(value) : null)
  }

  const handleEndDateChange = (value) => {
    setEndDate(value ? DateTime.fromISO(value) : null)
  }

  const isEndDateValid = () => {
    if (endDate && !endDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const isStartDateValid = () => {
    if (!startDate || !startDate.isValid) {
      return false
    }
    if (
      startDate &&
      endDate &&
      startDate.isValid &&
      endDate.isValid &&
      !startDate.until(endDate).isValid
    ) {
      return false
    }
    return true
  }

  const validateForm = () => {
    const errors = []
    if (!name) {
      errors.push(buildError('name', 'api.error.required-field', { fieldName: 'Name' }))
    }
    if (!isStartDateValid()) {
      if (!startDate) {
        errors.push(
          buildError('startDate', 'api.error.required-field', { fieldName: 'Start date' })
        )
      } else if (startDate && !startDate.isValid) {
        errors.push(buildError('startDate', 'api.error.invalid-date', { fieldName: 'Start date' }))
      } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
        errors.push(
          buildError('startDate', 'api.error.date-not-before-other-date', {
            firstDate: 'Start date',
            otherDate: 'end date',
          })
        )
      }
    }
    if (!isEndDateValid()) {
      if (endDate && !endDate.isValid) {
        errors.push(buildError('endDate', 'api.error.invalid-date', { fieldName: 'End date' }))
      } else if (startDate && endDate && endDate.isValid && !startDate.until(endDate).isValid) {
        errors.push(
          buildError('endDate', 'api.error.date-not-before-other-date', {
            firstDate: 'Start date',
            otherDate: 'end date',
          })
        )
      }
    }

    if (isProgram && !businessLineId) {
      errors.push(
        buildError('businessLineId', 'api.error.required-field', { fieldName: 'Business Line' })
      )
    }
    setErrorList(errors)
    return errors.length === 0
  }

  const handleSaveClicked = () => {
    if (validateForm()) {
      let entityToSave = {
        businessLineId: businessLineId ? businessLineId.id : undefined,
        description: description,
        endDate: endDate ? endDate.toISO() : null,
        name: name,
        startDate: startDate.toISO(),
      }
      saveCallback(entityToSave)
    }
  }

  useEffect(() => {
    console.log('entity', entity)

    if (entity && entity.businessLineId && businessLineOptions.length > 0) {
      console.log('entity.businessLineId', entity.businessLineId)
      const selectedBusinessLine = businessLineOptions.find(
        (each) => each.id === entity.businessLineId
      )
      console.log('selectedBusinessLine', selectedBusinessLine)
      setBusinessLineId(selectedBusinessLine)
    }
  }, [entity, businessLineOptions])

  return (
    <Dialog onClose={handleClose} open={openDialog}>
      <DialogTitle>
        {entity.id
          ? t('common.button.edit') + ' ' + entityName
          : t('common.button.add') + ' ' + entityName}
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
          <Grid item md={6} xs={12}>
            <MISTextField
              autoFocus
              error={isError() && hasError('name')}
              fullWidth
              helperText={getError('name')}
              label={t('types.table-header.name')}
              onChange={handleNameChange}
              required
              value={name}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MISTextField
              fullWidth
              label={t('types.table-header.description')}
              onChange={handleDescriptionChange}
              value={description}
            />
          </Grid>
          {isProgram && (
            <Grid item md={12} xs={12}>
              <MISSelectDropdown
                error={isError() && hasError('businessLineId')}
                fullWidth
                helperText={getError('businessLineId')}
                label={t('types.table-header.business-line')}
                onChange={(e) => {
                  setBusinessLineId(e.target.value)
                }}
                options={
                  businessLineOptions
                    ? businessLineOptions.map((each) => ({ label: each.name, value: each }))
                    : []
                }
                required
                value={businessLineId}
              />
            </Grid>
          )}

          <Grid item md={6} xs={12}>
            <MISDatePicker
              error={isError() && hasError('startDate')}
              helperText={getError('startDate')}
              isDefaultToday
              label={t('types.table-header.start-date')}
              onChange={(value) => handleStartDateChange(value)}
              readOnly={false}
              required
              value={startDate ? startDate.toISO() : ''}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <MISDatePicker
              error={isError() && hasError('endDate')}
              helperText={getError('endDate')}
              label={t('types.table-header.end-date')}
              onChange={(value) => handleEndDateChange(value)}
              readOnly={false}
              value={endDate ? endDate.toISO() : ''}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISButton onClick={handleClose}>{t('common.button.cancel')}</MISButton>
        <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
      </DialogActions>
    </Dialog>
  )
}
